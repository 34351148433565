import classNames from "classnames";

import type { UploadedFileProps } from "./UploadedFile.types";

import s from "./UploadedFile.module.scss";
import FileImage from "@/components/files/FileImage/FileImage";
import { ReactComponent as CancelIcon } from "@/assets/icons/cancel.svg";
import { getFileNameParts, getShortString } from "@/utils/helpers";
import type { FileType } from "@/utils/types";
import Text from "@/components/typography/Text/Text";
import { StyleType } from "@/utils/types/shared";

function UploadedFile({
  className,
  file,
  onDelete,
  disabled,
}: UploadedFileProps) {
  const { name, extension } = getFileNameParts(file.name);
  const shortName = getShortString(name, { trimLength: 10, trimEnd: 0 });

  function getClassByFileType() {
    const classByFileType: Record<FileType | string, StyleType> = {
      "application/pdf": "danger",
      "image/png": "accent",
      "image/jpg": "accent",
      "image/jpeg": "accent",
    };
    return classByFileType[file.type] || "";
  }

  return (
    <div className={classNames(s.uploaded, className)}>
      <div className={s.file}>
        <FileImage fileType={file.type} className={s.file__image} />
        {!disabled ? (
          <button type="button" className={s.delete} onClick={onDelete}>
            <CancelIcon className={s.delete__icon} />
          </button>
        ) : null}
      </div>
      <div className={s.info}>
        <p className={s.info__name}>
          {shortName}
          <Text type={getClassByFileType()}>{extension}</Text>
        </p>
      </div>
    </div>
  );
}

export default UploadedFile;
