import type { FileType } from '../../../types';

import type { FileExtensionFormat } from './getFileExtensionByType.types';

export function getFileExtensionByType(
  type: string,
  format: FileExtensionFormat = 'withDot',
): string | null {
  const typeMap: Record<FileType | string, string> = {
    'application/pdf': 'pdf',
    'image/png': 'png',
    'image/jpeg': 'jpeg',
    'image/jpg': 'jpg',
  };

  const extension = typeMap[type];

  if (!extension) {
    return null;
  }

  return format === 'withDot' ? `.${extension}` : extension;
}
