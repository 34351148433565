import React, { useState } from "react";

import styles from "./styles.module.scss";
import Button from "../../../../components/controls/Button/Button";
import showToast from "../../../../utils/helpers/toast/showToast";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import {
  useCancelOrderMutation,
  useGetActiveOrderQuery,
  useLazyGoNextOrderQuery,
} from "../../../../store/orders/orders.api";
import { IAutoPayoutItemResponse } from "../../../../store/autoPayouts/autoPayouts.types";
import { useCreateAutoPayoutMutation } from "../../../../store/autoPayouts/autoPayouts.api";
import { parsePhoneNumber } from "libphonenumber-js";

export default function EmployeeData({
  data,
}: {
  data?: IAutoPayoutItemResponse | undefined;
}) {
  const navigate = useNavigate();

  const [cancelOrder] = useCancelOrderMutation();
  const [goToNextOrder] = useLazyGoNextOrderQuery();
  const [createAutoPayout] = useCreateAutoPayoutMutation();
  const {
    isUninitialized: isActiveOrderUninitialized,
    refetch: refetchActive,
  } = useGetActiveOrderQuery();
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  async function createAutoPayoutAndOpen() {
    if (!data?.data.payout_order.id) {
      return showToast({
        value: "Ошибка",
        description: "Заявка не найдена",
        type: "error",
      });
    }
    try {
      const resp = await createAutoPayout({
        payout_order_uuid: data?.data.payout_order.uuid,
      }).unwrap();
      navigate(`/?auto_payout_modal=${resp.data.id}`);
    } catch (e) {}
  }

  async function onCancel() {
    if (!data?.data.payout_order.id) {
      return showToast({
        value: "Ошибка",
        description: "Заявка не найдена",
        type: "error",
      });
    }
    try {
      setIsCancelLoading(true);
      await cancelOrder({
        id: data?.data.payout_order.id,
      }).unwrap();
      await goToNextOrder();
      !isActiveOrderUninitialized && refetchActive();
      navigate("/");
    } catch (e) {
    } finally {
      setIsCancelLoading(false);
    }
  }

  return (
    <>
      <div className={styles.controls}>
        <h3 className={styles.title}>Вам разрешили перевод средств?</h3>
        <div className={styles.box}>
          <Button onClick={createAutoPayoutAndOpen}>
            Да, перевод разрешен
          </Button>
          <Button type="danger" loading={isCancelLoading} onClick={onCancel}>
            Нет, перевод заблокирован
          </Button>
        </div>
      </div>
      <div className={styles.container}>
        <h3 className={styles.title}>Данные ЛК для сотрудника Сбера</h3>

        <div className={styles.item}>
          <div className={styles.left}>ФИО</div>
          <div className={classNames(styles.right, styles.fio)}>
            {data?.data?.bank_profile?.fio.toLocaleLowerCase() || ""}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.left}>Дата рождения</div>
          <div className={styles.right}>Нет данных</div>
        </div>

        <div className={styles.item}>
          <div className={styles.left}>Телефон</div>
          <div className={styles.right}>
            {parsePhoneNumber(
              data?.data.bank_profile?.phone_number || ""
            )?.formatNational()}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.left}>Счет для выплат</div>
          <div className={styles.right}>
            {data?.data.material?.account_number
              ? `** ${data?.data.material?.account_number.slice(-4)}`
              : "Нет данных"}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.left}>Адрес</div>
          <div className={styles.right}>Нет данных </div>
        </div>
      </div>
    </>
  );
}
