import React, { ReactNode } from "react";

import styles from "./styles.module.scss";

import { motion } from "framer-motion";
import classNames from "classnames";
import Avatar from "../../shared/Avatar/Avatar";
import { StyleType } from "../../../utils/types/shared";
import { getColorFromType } from "../../../utils/helpers/getColorFromType";
import { hexToRgb, hexToRgbByCssVar } from "../../../utils/helpers/hexToRgb";

interface ISelectButton {
  isActive: boolean;
  onClick: () => void;
  icon: ReactNode;
  type?: StyleType;
  text: string;
}
export default function SelectButton({
  isActive,
  onClick,
  icon,
  type = "accent",
  text,
}: ISelectButton) {
  return (
    <motion.button
      className={classNames(styles.variant, {
        [styles.active]: isActive,
      })}
      style={{
        border: isActive
          ? `1px solid var(--${getColorFromType(type)}-color)`
          : "",
        boxShadow: isActive
          ? `0px 4px 10px 0px rgba(${hexToRgbByCssVar(
              `--${getColorFromType(type)}-color`
            )}, 0.1)`
          : "",
      }}
      onClick={onClick}
    >
      <Avatar type={type}>{icon}</Avatar>
      <p>{text}</p>
    </motion.button>
  );
}
