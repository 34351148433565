import { ReactNode } from "react";

import styles from "./styles.module.scss";

interface IInfoItem {
  title: string;
  icon: ReactNode;
  leftValue: ReactNode;
  leftDescription?: string;
}
export default function InfoItem(props: IInfoItem) {
  return (
    <div className={styles.infoItem}>
      <h3 className={styles.infoTitle}>{props.title}</h3>
      <div className={styles.infoBox}>
        <div className={styles.infoLeft}>
          <div className={styles.value}>{props.leftValue}</div>
          {props.leftDescription && (
            <div className={styles.description}>{props.leftDescription}</div>
          )}
        </div>
        <div className={styles.infoRight}>{props.icon}</div>
      </div>
    </div>
  );
}
