import React, { useState } from "react";

import styles from "./styles.module.scss";

import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import Text from "../../typography/Text/Text";
import { useGetActiveOrderQuery } from "../../../store/orders/orders.api";
import OrderItem from "../../../pages/OrdersPage/OrderItem/OrderItem";
import SelectButton from "../../controls/SelectButton/SelectButton";
import { ReactComponent as CardXIcon } from "../../../assets/icons/credit-card-x.svg";
import { ReactComponent as CardUpIcon } from "../../../assets/icons/credit-card-upload.svg";
import Button from "../../controls/Button/Button";
import { motionButtonProps } from "../../../utils/styles/motionSettings";
import { motion } from "framer-motion";

interface IModalDisableExecutor extends IModalBase {
  onDisableAccept(): void;
}
export default function ModalDisableExecutor(props: IModalDisableExecutor) {
  const { data } = useGetActiveOrderQuery();
  const [selected, setSelected] = useState("");

  function onSubmit() {
    if (selected === "disable") {
      props.onDisableAccept();
      props.onClose();
      return;
    }
    return props.onClose();
  }

  return (
    <ModalBase {...props}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.intro}>
            <h2>
              Вы действительно хотите <br /> отключить токен?
            </h2>

            <p>
              <Text type="accent">У вас есть активная заявка,</Text> при
              отключении токена она вернется в общую очередь
            </p>
          </div>
          {data?.data && (
            <OrderItem data={data.data} isOpen={false} onOpenClick={() => {}} />
          )}
          <div className={styles.select}>
            <div className={styles.variants}>
              <SelectButton
                icon={<CardXIcon />}
                isActive={selected === "disable"}
                text={"Отключить токен, заявку на выплату оплачивать не буду"}
                type="danger"
                onClick={() => setSelected("disable")}
              />
              <SelectButton
                icon={<CardUpIcon />}
                isActive={selected === "notDisable"}
                text={"Не отключать токен, оплачу заявку"}
                onClick={() => setSelected("notDisable")}
              />
            </div>
          </div>
        </div>

        <motion.div {...motionButtonProps}>
          <Button onClick={onSubmit} size="lg" disabled={selected === ""}>
            Подтвердить
          </Button>
        </motion.div>
      </div>
    </ModalBase>
  );
}
