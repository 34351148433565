import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useGetUserInfoQuery } from "../../../store/user/user.api";

export default function Header() {
  const { data, isLoading } = useGetUserInfoQuery();
  return (
    <header className={styles.header}>
      <Link to={"/"} className={styles.logo}>
        <Logo />
      </Link>
      {isLoading ? (
        <Skeleton sx={{ height: "16px", width: "120px" }} />
      ) : (
        <Link to={"/profile"} className={styles.name}>
          {data?.data.name}
        </Link>
      )}
    </header>
  );
}
