import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "../../../utils/styles/sharedStyles.module.scss";

import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useSearchParams } from "react-router-dom";
import { IWrapComponent } from "../../../utils/types/shared";
import useFiltersTags from "../../../utils/hooks/useFiltersTags";
import Text from "../../typography/Text/Text";
import FilterTag from "../FilterTag/FilterTag";
import useGetOrderTypeData from "@/utils/hooks/stateSwitchers/useGetOrderTypeData";
import { transformCurrencyValue } from "../../../utils/helpers/transformCurrencyValue";
import { useTranslation } from "react-i18next";

export default function FiltersBaseTags({ children }: IWrapComponent) {
  const { t } = useTranslation(undefined, { keyPrefix: "ordersPage.tags" });
  const { t: tF } = useTranslation(undefined, {
    keyPrefix: "ordersPage.filters",
  });
  const [searchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams);
  const getTypeData = useGetOrderTypeData();

  const { isTagsIncludes, clearFilters } = useFiltersTags();

  if (!isTagsIncludes) {
    return <></>;
  }
  return (
    <div className={sharedStyles.filtersContainer}>
      <div className={sharedStyles.filtersResults}>
        <div className={sharedStyles.desktop}>
          <button onClick={clearFilters}>
            <Text type="accent">{t("clear")}</Text>
          </button>
        </div>
        <div className={sharedStyles.mobile}>
          <div className={sharedStyles.filtersTitle}>
            <Text type="muted">{t("filtersTitle")}</Text>
            <button onClick={clearFilters}>
              <Text type="accent">{t("clear")}</Text>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {children}

        {searchParamsObject.state && (
          <FilterTag
            query={"state"}
            title={tF("deal.title")}
            value={getTypeData(searchParamsObject.state).title}
          />
        )}

        {searchParamsObject.amountFrom && (
          <FilterTag
            query={"amountFrom"}
            title={tF("amount.amountFrom")}
            value={`${
              searchParamsObject.amountFrom
                ? `${transformCurrencyValue(
                    +searchParamsObject.amountFrom
                  )} ${"RUB"}`
                : ""
            }`}
          />
        )}
        {searchParamsObject.amountTo && (
          <FilterTag
            query={"amountTo"}
            title={tF("amount.amountTo")}
            value={`${
              searchParamsObject.amountTo
                ? `${transformCurrencyValue(
                    +searchParamsObject.amountTo
                  )} ${"RUB"}`
                : ""
            }`}
          />
        )}

        {searchParamsObject.dateFrom && (
          <FilterTag
            query={"dateFrom"}
            title={t("date.dateFrom")}
            value={`${
              searchParamsObject.dateFrom
                ? `${dayjs(+searchParamsObject.dateFrom)
                    .locale("ru")
                    .format("DD.MM.YYYY HH:mm")}`
                : ""
            }`}
          />
        )}
        {searchParamsObject.dateTo && (
          <FilterTag
            query={"dateTo"}
            title={t("date.dateTo")}
            value={`${
              searchParamsObject.dateTo
                ? `${dayjs(+searchParamsObject.dateTo)
                    .locale("ru")
                    .format("DD.MM.YYYY HH:mm")}`
                : ""
            }`}
          />
        )}
      </div>
    </div>
  );
}
