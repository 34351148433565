import { useSearchParams } from "react-router-dom";

export default function useFiltersTags() {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsObject = Object.fromEntries(searchParams);
  function clearFilters() {
    let newQuery: any = {};
    for (let key in searchParamsObject) {
      if (key.includes("modal_")) {
        newQuery[key] = searchParamsObject[key];
      }
      if (key.includes("tab")) {
        newQuery[key] = searchParamsObject[key];
      }
      if (key.includes("id")) {
        newQuery[key] = searchParamsObject[key];
      }
    }
    setSearchParams(new URLSearchParams(newQuery));
  }

  let tagsData: any = {};
  for (let key in searchParamsObject) {
    if (!key.includes("modal_") && key !== "tab" && key !== "id") {
      tagsData[key] = searchParamsObject[key];
    }
  }

  const isTagsIncludes =
    Object.values(tagsData).filter((i: any) => i.length > 0).length > 0;

  return { isTagsIncludes, clearFilters };
}
