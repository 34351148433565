import React from "react";
import { useTranslation } from "react-i18next";

export default function useGetRemainingTime() {
  const { t } = useTranslation(undefined, { keyPrefix: "ordersPage.time" });

  function getRemainingTime(targetDate: Date): string {
    const now = new Date();
    const diff = targetDate.getTime() - now.getTime();

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours} ${t("ch")} ${minutes} ${t("min")}`;
  }

  return getRemainingTime;
}
