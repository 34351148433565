import React, { useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

import ReactModal from "react-modal";
import Button from "../../controls/Button/Button";
import useOnClickOutside from "../../../utils/hooks/useClickOutside";
import Block from "../../shared/Block/Block";
import ErrorParser from "../../shared/ErrorParser/ErrorParser";
import { IOptionalWrapComponent, StyleType } from "../../../utils/types/shared";

export interface IModalConfirm extends IOptionalWrapComponent {
  title?: string;
  subTitle: string | React.ReactElement;
  onConfirm?: () => void;
  confirmText?: string;
  isOpen: boolean;
  closeModal: () => void;
  isConfirmLoading?: boolean;
  confirmType?: StyleType;
  error?: any;
}

export default function ModalConfirm(props: IModalConfirm) {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const contentRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(contentRef, props.closeModal);
  if (!isBrowser) {
    return <></>;
  }
  return (
    <ReactModal
      appElement={document && document?.body}
      overlayClassName={styles.overlay}
      className={styles.modal}
      style={{
        overlay: {},
        content: {},
      }}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <div className={styles.container}>
        <h3 className={styles.title}>{props.title}</h3>

        {props.subTitle && (
          <div className={styles.subTitle}>{props.subTitle}</div>
        )}

        {props.children && props.children}
        <Block>
          {props.error && <ErrorParser error={props.error} />}
          <div className={styles.controls}>
            <Button
              loading={props.isConfirmLoading}
              type={props.confirmType || "accent"}
              onClick={props.onConfirm}
            >
              {props.confirmText ? props.confirmText : "Подтвердить"}
            </Button>
            <Button type="primary" onClick={props.closeModal}>
              Отмена
            </Button>
          </div>
        </Block>
      </div>
    </ReactModal>
  );
}
