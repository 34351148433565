import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import profileStyles from "../styles.module.scss";

import { Skeleton, Tooltip } from "@mui/material";
import { useAppDispatch } from "@/store/hooks";
import {
  useChangeExecutorMutation,
  useGetExecutorQuery,
} from "@/store/executor/executor.api";
import { ordersApi } from "@/store/orders/orders.api";
import { useTranslation } from "react-i18next";
import Text from "@/components/typography/Text/Text";
import AppSwitch from "@/components/shared/AppSwitch/AppSwitch";
import classNames from "classnames";

export default function AccountBlock() {
  const { t } = useTranslation(undefined, { keyPrefix: "profilePage.account" });
  const dispatch = useAppDispatch();
  const [isС2СEnabled, setС2СEnabled] = useState(true);
  const { data: executorData, isLoading: isExecutorLoading } =
    useGetExecutorQuery();
  const [changeExecutor] = useChangeExecutorMutation();

  const handleChange = async () => {
    setС2СEnabled((state) => !state);
    if (executorData?.data?.state) {
      try {
        await changeExecutor({
          account_number_transfer_enabled:
            !executorData.data.account_number_transfer_enabled,
        }).unwrap();
        dispatch(ordersApi.util.resetApiState());
      } catch {}
    }
  };

  useEffect(() => {
    if (executorData?.data?.state) {
      setС2СEnabled(executorData?.data?.account_number_transfer_enabled);
    }
  }, [executorData]);

  function getTokenSwitchText() {
    if (isExecutorLoading) {
      return <Skeleton height={19} width={128} />;
    }
    return (
      <Text
        type={
          executorData?.data?.account_number_transfer_enabled
            ? "accent"
            : "muted"
        }
      >
        {t("switch")}
      </Text>
    );
  }

  function getTokenDescription() {
    if (isExecutorLoading) {
      return <Skeleton width={"100%"} height={42} />;
    }
    return t("description");
  }
  return (
    <div className={classNames(profileStyles.paper, styles.container)}>
      <div className={styles.tokenSwitchContainer}>
        <Tooltip title={isС2СEnabled ? t("disable") : t("enable")}>
          <AppSwitch
            disabled={isExecutorLoading}
            checked={isС2СEnabled}
            onChange={handleChange}
          />
        </Tooltip>
        <p className={styles.tokenSwitchText}>{getTokenSwitchText()}</p>
      </div>
      <p className={styles.tokenDescription}>{getTokenDescription()}</p>
    </div>
  );
}
