import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as SberIcon } from "../../../assets/icons/sber-accent.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/x-close.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone-call-01.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-circle.svg";
import { ReactComponent as ClockIcon } from "../../../assets/icons/clock-refresh.svg";
import { IAutoPayoutItemResponse } from "../../../store/autoPayouts/autoPayouts.types";
import EmployeeData from "./EmployeeData/EmployeeData";
import Status from "../../../components/shared/Status/Status";
import Confirmation from "../../../components/modals/ModalOrderAccept/Confirmation/Confirmation";
import { Skeleton } from "@mui/material";

interface IStatusSwitcher {
  state?: string;
  data?: IAutoPayoutItemResponse | undefined;
  isPayoutPage?: boolean;
  isLoading?: boolean;
  refetchAutoPayout?: () => void;
  isUninitializedAutoPayout?: boolean;
}
export default function StatusSwitcher({
  state,
  data,
  isPayoutPage,
  isLoading,
  isUninitializedAutoPayout,
  refetchAutoPayout,
}: IStatusSwitcher) {
  if (isLoading)
    return (
      <Status
        icon={<Skeleton variant="circular" width={24} height={24} />}
        title={<Skeleton width={180} variant="rounded" height={18} />}
        type="loading"
        description={
          <>
            <Skeleton width={230} variant="rounded" height={15} />
            <Skeleton
              width={100}
              variant="rounded"
              height={15}
              sx={{ mx: "auto", mt: "8px" }}
            />
          </>
        }
      />
    );
  if (data?.data?.error_code === "SUSPENDED_FOR_SECURITY_REASON") {
    return (
      <Status
        icon={<PhoneIcon />}
        title="Звонок из банка"
        type="danger"
        description={data.data?.message || ""}
      >
        <EmployeeData data={data} />
      </Status>
    );
  }
  switch (state) {
    case "confirmation":
      return isPayoutPage ? (
        <Status
          icon={<SberIcon className={styles.sberIcon} />}
          title="Оплата из ЛК Сбера"
          type="accent"
          description={
            <>
              Платеж в процессе обработки <br /> со стороны банка
            </>
          }
        />
      ) : (
        <Confirmation
          isUninitializedAutoPayout={isUninitializedAutoPayout}
          refetchAutoPayout={refetchAutoPayout}
          data={data}
        />
      );
    case "completed": {
      return (
        <Status
          icon={<CheckIcon />}
          title="Успех"
          type="success"
          description="Платеж успешно выполнен"
        />
      );
    }
    case "processing": {
      return (
        <Status
          icon={<SberIcon className={styles.sberIcon} />}
          title="Оплата из ЛК Сбера"
          type="accent"
          description={
            <>
              Платеж в процессе обработки <br /> со стороны банка
            </>
          }
        />
      );
    }
    case "pending": {
      return (
        <Status
          icon={<ClockIcon />}
          title="В очереди на выполнение"
          description="Выплата создана и ожидает в очереди на выполнение"
          type="warning"
        />
      );
    }
    case "initialization": {
      return (
        <Status
          icon={<ClockIcon />}
          title="Инициализация платежа"
          description="Инициализация платежа на стороне банка"
          type="warning"
        />
      );
    }
    case "failed": {
      return (
        <Status
          icon={<XIcon />}
          title="Ошибка"
          type="danger"
          description={data?.data.message}
        />
      );
    }
    case "expired": {
      return (
        <Status
          icon={<XIcon />}
          title="Заявка просрочена"
          type="danger"
          description="Заявка не была подтверждена в течение 10 минут"
        />
      );
    }
    default: {
      return (
        <Status
          icon={<XIcon />}
          title="Нераспознан статус заявки"
          type="danger"
          description="Ошибка, не удалось определить статус заявки"
        />
      );
    }
  }
}
