const orderStatesText = {
  orderStates: {
    completed: {
      title: "Заявка оплачена",
      description: "Платеж прошел проверку",
    },
    canceled: {
      title: "Заявка отменена",
      description: "Вы отменили заявку",
    },
    expired: {
      title: "Время истекло",
      description: "Истекло время на оплату",
    },
    verification_failed: {
      title: "Не оплачена",
      description: "Проверьте чек",
    },
    verification: {
      title: "Проверка платежа",
      description: "Система сканирует чек об операции",
    },
    manual_verification: {
      title: "Проверка администрацией",
      description: "Платеж на проверке",
    },
    pending: {
      title: "Ожидает оплаты",
    },
    paid: {
      title: "Ожидает загрузки чека",
    },
    default: {
      title: "Ошибка: заявка не распознана",
    },
  },
};

export default orderStatesText;
