import React from "react";

import styles from "../styles.module.scss";

import { IOrderItem } from "../../../../store/orders/orders.types";
import { motion } from "framer-motion";
import {
  useCreateAutoPayoutMutation,
  useGetAutoPayoutQuery,
} from "../../../../store/autoPayouts/autoPayouts.api";
import { ReactComponent as SberIcon } from "../../../../assets/icons/sber.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AutoPayoutButton({ data }: { data: IOrderItem }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [createAutoPayout] = useCreateAutoPayoutMutation();
  const { data: autoPayoutData } = useGetAutoPayoutQuery(
    { id: data.auto_payout_id as string },
    {
      skip: !data.auto_payout_id,
    }
  );

  async function createAutoPayoutAndOpen() {
    try {
      const resp = await createAutoPayout({
        payout_order_uuid: data.uuid,
      }).unwrap();

      searchParams.set("auto_payout_modal", String(resp.data.id));
      setSearchParams(searchParams);
    } catch (e) {}
  }

  function openCurrentAutoPayout() {
    if (!autoPayoutData?.data) {
      return;
    }
    const { state } = autoPayoutData.data;
    if (
      state === "confirmation" ||
      state === "initialization" ||
      state === "pending"
    ) {
      searchParams.set("auto_payout_modal", String(data.auto_payout_id));
      setSearchParams(searchParams);
      return;
    }
    navigate(`/auto-order/${data.auto_payout_id}`);
  }
  function onButtonClick() {
    if (!data.id) {
      return;
    }
    if (data?.auto_payout_id) {
      openCurrentAutoPayout();
      return;
    }
    createAutoPayoutAndOpen();
  }

  if (!data.auto_payout_allowed && !data?.auto_payout_id) {
    return <></>;
  }
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={styles.sber}
      onClick={onButtonClick}
    >
      <SberIcon />
      <span>
        {data?.auto_payout_id
          ? "Открыть выплату"
          : "Оплатить через Сбер Онлайн"}
      </span>
    </motion.button>
  );
}
