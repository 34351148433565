import React from "react";

import styles from "./styles.module.scss";
import { ReactComponent as CancelIcon } from "../../../assets/icons/x-close.svg";
import { useSearchParams } from "react-router-dom";
import Text from "../../typography/Text/Text";

interface IFilterTag {
  title: string;
  value: string;
  query: string | string[];
}

export default function FilterTag({ query, title, value }: IFilterTag) {
  const [searchParams, setSearchParams] = useSearchParams();

  function clear() {
    if (Array.isArray(query)) {
      query.forEach((q) => {
        searchParams.delete(q);
      });
    } else {
      searchParams.delete(query);
    }
    setSearchParams(searchParams);
  }

  return (
    <div className={styles.tag}>
      <div>
        {title}: <Text type="accent">{value}</Text>
      </div>
      <button onClick={clear}>
        <CancelIcon />
      </button>
    </div>
  );
}
