import React from "react";

import { ReactComponent as CheckIcon } from "@/assets/icons/check-circle.svg";
import { ReactComponent as XIcon } from "@/assets/icons/x-circle.svg";
import { ReactComponent as CardIcon } from "@/assets/icons/credit-card-upload.svg";
import { ReactComponent as EyeIcon } from "@/assets/icons/eye.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/file-05.svg";
import { useTranslation } from "react-i18next";

export default function useGetOrderTypeData() {
  const { t } = useTranslation(undefined, { keyPrefix: "orderStates" });
  function getTypeData(state: string) {
    switch (state) {
      case "completed":
        return {
          type: "success",
          title: t("completed.title"),
          description: t("completed.description"),
          icon: <CheckIcon />,
        };
      case "canceled":
        return {
          type: "danger",
          title: t("canceled.title"),
          description: t("canceled.description"),
          icon: <XIcon />,
        };
      case "expired":
        return {
          type: "danger",
          title: t("expired.title"),
          description: t("expired.description"),
          icon: <XIcon />,
        };
      case "verification_failed":
        return {
          type: "danger",
          title: t("verification_failed.title"),
          description: t("verification_failed.description"),
          icon: <XIcon />,
        };
      case "verification":
        return {
          type: "warning",
          title: t("verification.title"),
          description: t("verification.description"),
          icon: <EyeIcon />,
        };
      case "manual_verification":
        return {
          type: "warning",
          title: t("manual_verification.title"),
          description: t("manual_verification.description"),
          icon: <EyeIcon />,
        };
      case "pending":
      case "processing":
        return {
          type: "accent",
          title: t("pending.title"),
          icon: <CardIcon />,
        };
      case "paid":
        return {
          type: "purple",
          title: t("paid.title"),
          icon: <FileIcon />,
        };
      default:
        return {
          type: "muted",
          title: t("default.title"),
          icon: <XIcon />,
        };
    }
  }
  return getTypeData;
}
