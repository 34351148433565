export function getBarType(barValue: number) {
  if (barValue <= 0) {
    return "placeHolder";
  }
  if (barValue < 29.99) {
    return "danger";
  }
  if (barValue < 59.99) {
    return "warning";
  }
  return "success";
}
export function getBarColor(type: string) {
  switch (type) {
    case "accent":
      return "linear-gradient(270deg, #0052FF 0%, #F9FAFB 100%)";
    case "warning":
      return "linear-gradient(270deg, #FEB865 0%, #FBFCFE 100%)";
    case "placeholder":
      return "linear-gradient(270deg, #C7CDE0 0%, #FBFCFE 100%)";
    case "success":
      return "linear-gradient(270deg, #7BD765 0%, #FBFCFE 100%)";
    case "danger":
      return "linear-gradient(270deg, #FF6464 0%, #FBFCFE 100%)";
    default:
      return "linear-gradient(270deg, #C7CDE0 0%, #FBFCFE 100%)";
  }
}
