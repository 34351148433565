import React from "react";

import styles from "./styles.module.scss";

export default function ProcessingWrapper({
  children,
  title,
}: {
  children: React.ReactNode;
  title: React.ReactNode;
}) {
  return (
    <div className={styles.processingBlock}>
      <div className={styles.processingBlockTitle}>{title}</div>
      {children}
    </div>
  );
}
