import React from "react";
import Text from "../../typography/Text/Text";

export default function Amount({
  value,
  postfix,
}: {
  value: string;
  postfix?: string;
}) {
  const [beforeDecimal, afterDecimal] = value.split(".");

  return (
    <>
      <span>{parseInt(beforeDecimal)}</span>,
      <Text type="muted">
        {parseInt(afterDecimal || "0")} {postfix}
      </Text>
    </>
  );
}
