import React from "react";

import { useGetPayoutLimitsQuery } from "../../store/payoutLimits/payoutLimits.api";
import { IActiveOrderResponse } from "../../store/orders/orders.types";

export default function useGetReservesState(
  activeOrderData?: IActiveOrderResponse,
  isActiveOrderError?: boolean
) {
  const { data: reservesData } = useGetPayoutLimitsQuery();

  let isReservesOver = false;
  // let bankReserve: any = reservesData?.data
  //   ? Math.max.apply(
  //       null,
  //       reservesData?.data?.map((i) => i.limit)
  //     )
  //   : null;
  // if (!activeOrderData?.data.is_final_state) {
  //   if (activeOrderData?.data) {
  //     if (bankReserve && bankReserve < activeOrderData?.data.amount) {
  //       isReservesOver = true;
  //     }
  //   }
  // }

  // if (
  //   (isActiveOrderError || activeOrderData?.data.is_final_state !== true) &&
  //   bankReserve === 0
  // ) {
  //   isReservesOver = true;
  // }
  return isReservesOver;
}
