import React from "react";

import styled from "@emotion/styled";
import { Switch } from "@mui/material";

const AppSwitch = styled(Switch)(() => ({
  padding: 8,
  left: -8,

  "& .Mui-checked": {
    "+ .MuiSwitch-track": {
      backgroundColor: "#0052ff",
      opacity: "1 !important",
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "#ffffff",
    },
  },

  "& .Mui-disabled": {
    opacity: 0.5,
    "+ .MuiSwitch-track": {
      opacity: "0.5 !important",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#EBEEF8",
    opacity: 1,

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    backgroundColor: "#8091B5",
    width: 16,
    height: 16,
    margin: 2,
    transition:
      "left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));

export default AppSwitch;
