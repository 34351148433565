import React from "react";

import styles from "./styles.module.scss";

import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import StatusSwitcher from "../../../pages/AutoOrderPage/StatusSwitcher/StatusSwitcher";
import useGetAutoPayout from "../../../utils/hooks/useGetAutoPayout";

interface IModalOrderAccept extends IModalBase {
  id: number | string;
}

export default function ModalOrderAccept(props: IModalOrderAccept) {
  const { id, ...rest } = props;
  const { data, isLoading, refetch, isUninitialized } = useGetAutoPayout({
    id,
    stopPollingValues: ["confirmation", "failed", "completed"],
  });
  return (
    <ModalBase {...rest}>
      <StatusSwitcher
        isUninitializedAutoPayout={isUninitialized}
        refetchAutoPayout={refetch}
        isLoading={isLoading}
        data={data}
        state={data?.data.state}
      />
    </ModalBase>
  );
}
