import React from "react";

import styles from "./styles.module.scss";
import Text from "../../typography/Text/Text";
import { toPascalCase } from "../../../utils/helpers/toPascalCase";

interface ITranslateParams {
  key: string;
  translate: string;
}

function getTranslateKey(key: string, translates?: ITranslateParams[]) {
  if (translates) {
    const translate = translates.find((item) => item?.key === key);
    if (translate) {
      return translate?.translate;
    }
  }

  return toPascalCase(key.replace("_", " "));
}

function translateIncludesText(value: string, translate: ITranslateParams) {
  const index = value.indexOf(translate.key);
  if (index !== -1) {
    const maskedPart = value.slice(index, index + translate.key.length);
    const maskedStr = value.replace(maskedPart, translate.translate);
    return maskedStr;
  }
  return value;
}

function getTranslateValue(value: string, translates?: ITranslateParams[]) {
  if (translates) {
    const translate = translates.find((item) => {
      return value.includes(item.key);
    });
    if (translate) {
      return translateIncludesText(value, translate);
    }
  }
  return value;
}

export interface IErrorParserTranslates {
  keys: ITranslateParams[];
  values: ITranslateParams[];
}
export interface IErrorParser {
  error: any;
  translates?: IErrorParserTranslates;
  messageTranslates?: ITranslateParams[];
}
export default function ErrorParser({
  error,
  translates,
  messageTranslates,
}: IErrorParser) {
  if (error?.data?.errors && Object.keys(error?.data?.errors).length > 0) {
    const { errors } = error?.data;
    const errorsKeys = Object.keys(errors);
    const errorsValues: string[] = Object.values(errors);

    return (
      <div className={styles.container}>
        {errorsKeys.map((key, indx) => (
          <p key={key}>
            <Text className={styles.text} type="danger">
              {getTranslateKey(key, translates?.keys)}:{" "}
              {getTranslateValue(errorsValues[indx][0], translates?.values) ||
                ""}
            </Text>
          </p>
        ))}
      </div>
    );
  }

  if (error?.data?.message) {
    return (
      <Text className={styles.text} type="danger">
        {messageTranslates
          ? getTranslateValue(error.data.message, messageTranslates)
          : error.data.message}
      </Text>
    );
  }

  if (typeof error === "string") {
    return (
      <Text className={styles.text} type="danger">
        {error}
      </Text>
    );
  }

  return (
    <Text className={styles.text} type="danger">
      {JSON.stringify(error)}
    </Text>
  );
}
