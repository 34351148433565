import orderStatesText from "./orderStates";
import notFoundPageText from "./pages/notFound";
import orderPageText from "./pages/orderPage";
import ordersPageText from "./pages/ordersPage";
import profilePageText from "./pages/profilePage";

const ru = {
  ...notFoundPageText,
  ...ordersPageText,
  ...orderStatesText,
  ...profilePageText,
  ...orderPageText,
};

export default ru;
