import React, { useEffect, useState } from "react";
import { useGetAutoPayoutQuery } from "../../store/autoPayouts/autoPayouts.api";

export default function useGetAutoPayout({
  id,
  stopPollingValues,
}: {
  id?: string | number;
  stopPollingValues: string[];
}) {
  const [stopPolling, setStopPolling] = useState(false);

  const { data, ...rest } = useGetAutoPayoutQuery(
    { id: id as string },
    { pollingInterval: stopPolling ? undefined : 6000, skip: !id }
  );

  useEffect(() => {
    if (stopPollingValues.includes(data?.data.state || "")) {
      setStopPolling(true);
    } else {
      setStopPolling(false);
    }
  }, [data?.data.state, stopPollingValues]);

  return { data, ...rest };
}
