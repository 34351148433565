import styles from "./styles.module.scss";

import { toast } from "react-toastify";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success-toast.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/error-toast.svg";

interface IShowToast {
  value: string;
  description: string;
  type?: "success" | "error" | "info";
  icon?: any;
}

export default function showToast({
  value,
  description,
  type = "success",
  icon,
}: IShowToast) {
  toast(
    <div className={styles.container}>
      <div className={styles.value}>{value}</div>
      <div className={styles.description}>{description}</div>
    </div>,
    {
      icon: icon || (type === "error" ? <ErrorIcon /> : <SuccessIcon />),
      type,
    }
  );
}
