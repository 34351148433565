import React from "react";

import {
  useGetActiveOrderQuery,
  useLazyGoNextOrderQuery,
} from "../../store/orders/orders.api";
import { useDispatch } from "react-redux";
import { openModalEmptyNext } from "../../store/ui/ui.slice";

export default function useGoNextOrder({
  skipActiveQuery,
}: {
  skipActiveQuery?: boolean;
}) {
  const dispatch = useDispatch();
  const [goToNextOrder, { ...state }] = useLazyGoNextOrderQuery();

  const {
    refetch: refetchActive,
    isUninitialized: isActiveOrderUninitialized,
  } = useGetActiveOrderQuery(undefined, { skip: skipActiveQuery });

  async function getNextOrder() {
    try {
      await goToNextOrder().unwrap();
    } catch (e: any) {
      e?.status === 404 && dispatch(openModalEmptyNext());
    }
    !isActiveOrderUninitialized && refetchActive();
  }

  return { state, getNextOrder };
}
