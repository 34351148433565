import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

const initialState = {
  isModalEmptyNextOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openModalEmptyNext(state) {
      state.isModalEmptyNextOpen = true;
    },
    closeModalEmptyNext(state) {
      state.isModalEmptyNextOpen = false;
    },
  },
});

export const { closeModalEmptyNext, openModalEmptyNext } = uiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectIsModalEmptyNextOpen = (state: RootState) =>
  state.ui.isModalEmptyNextOpen;

export default uiSlice.reducer;
