import type { FileInputPlaceholderProps } from "./FileInputPlaceholder.types";

import { ReactComponent as FileIcon } from "@/assets/icons/file.svg";
import s from "./FileInputPlaceholder.module.scss";
import { useTranslation } from "react-i18next";

function FileInputPlaceholder(props: FileInputPlaceholderProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.fileInputDrop",
  });
  const { text = t("placeholder") } = props;
  return (
    <div className={s.placeholder}>
      <FileIcon className={s.placeholder__icon} />
      <p className={s.placeholder__text}>{text}</p>
    </div>
  );
}

export default FileInputPlaceholder;
