import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import formStyles from "../styles.module.scss";

import classNames from "classnames";
import { ReactComponent as OrderIcon } from "../../../../assets/icons/clock-refresh.svg";
import { IFilterItem, QueryType } from "../../../../utils/types/shared";
import DropDown from "../../../controls/DropDown/DropDown";
import { useTranslation } from "react-i18next";

export default function FilterDealType({
  formState,
  setFormState,
  isHiddenFields,
  setIsHiddenFields,
}: IFilterItem) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "ordersPage.filters.deal",
  });
  const { t: tState } = useTranslation(undefined, { keyPrefix: "orderStates" });
  const [isDdActive, setIsDdActive] = useState(false);

  const initialStatuses = [
    {
      state: "",
      value: t("all"),
      selected: false,
    },
    {
      state: "completed",
      value: tState("completed.title"),
      selected: false,
    },
    {
      state: "canceled",
      value: tState("canceled.title"),
      selected: false,
    },
    {
      state: "expired",
      value: tState("expired.title"),
      selected: false,
    },
    {
      state: "verification_failed",
      value: tState("verification_failed.title"),
      selected: false,
    },
    {
      state: "verification",
      value: tState("verification.title"),
      selected: false,
    },
    {
      state: "manual_verification",
      value: tState("manual_verification.title"),
      selected: false,
    },
    {
      state: "processing",
      value: tState("pending.title"),
      selected: false,
    },
    {
      state: "paid",
      value: tState("paid.title"),
      selected: false,
    },
  ];
  function onTypeChange(value: string, index?: number) {
    let selectedType: QueryType = "";
    if (index) {
      selectedType = initialStatuses[index].state;
    }
    setFormState({ state: selectedType });
  }

  useEffect(() => {
    setIsHiddenFields(isDdActive);
  }, [isDdActive]);
  return (
    <div
      className={classNames(formStyles.item, {
        [formStyles.hidden]: isHiddenFields && !isDdActive,
      })}
    >
      <div className={formStyles.itemHead}>
        <OrderIcon />
        <div>{t("title")}:</div>
      </div>
      <DropDown
        onChange={onTypeChange}
        size="md"
        items={initialStatuses.map((i) => i.value)}
        selected={
          formState?.state &&
          initialStatuses.find((i) => i.state === formState.state)?.value
        }
        selectedClassName={styles.ddSelected}
        onActiveChange={(bool: boolean) => setIsDdActive(bool)}
      />
    </div>
  );
}
