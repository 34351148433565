import React from "react";
import { useGetBanksListQuery } from "../../store/payoutLimits/payoutLimits.api";

export default function useGetBank({ bankAlias }: { bankAlias: string }) {
  const { data: banksData } = useGetBanksListQuery({ regionId: 1 });

  let bank;

  if (banksData?.data) {
    bank = banksData.data.find((i) => i.alias === bankAlias);
  }
  return bank;
}
