import React from "react";

import styles from "./styles.module.scss";
const size = 44;
const strokeWidth = 5;

interface IPercentageCircle {
  children?: React.ReactNode;
  percentage: number;
  type?: "default" | "success";
}

export default React.memo(function PercentageCircle(props: IPercentageCircle) {
  const { type = "default", children, percentage } = props;
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;
  let color = type === "success" ? "#22DD9A" : "#0052ff";
  let bgColor = type === "success" ? "#E9FCF5" : "#E9F3FE";

  return (
    <div className={styles.container}>
      {children && <div className={styles.icon}>{children}</div>}
      <svg width={size} height={size} viewBox={viewBox}>
        <circle
          fill="none"
          stroke={bgColor}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          fill="none"
          stroke={color}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          //   @ts-ignore
          strokeDasharray={[dash, circumference - dash]}
          strokeLinecap="round"
          style={{ transition: `all ${0.5}s ease-in-out` }}
        />
      </svg>
    </div>
  );
});
