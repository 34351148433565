import React, { useEffect } from "react";

import styles from "../styles.module.scss";

import { ReactComponent as CardIcon } from "@/assets/icons/credit-card-upload.svg";
import { motion } from "framer-motion";
import { motionButtonProps } from "@/utils/styles/motionSettings";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import useGoNextOrder from "@/utils/hooks/useGoNextOrder";
import ErrorParser from "@/components/shared/ErrorParser/ErrorParser";
import Block from "@/components/shared/Block/Block";

export default function NextOrderButton() {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.nextOrder",
  });
  const { getNextOrder, state } = useGoNextOrder({});
  const error: any = state?.error;

  return (
    <Block>
      {error && error?.status !== 404 && (
        <ErrorParser
          error={error}
          translates={{
            keys: [
              {
                key: "base",
                translate: t("translates.keys.base"),
              },
            ],
            values: [
              {
                key: "token is suspended",
                translate: t("translates.values.suspended"),
              },
            ],
          }}
        />
      )}

      <motion.button
        {...motionButtonProps}
        className={classNames(styles.paper, styles.newOrder)}
        onClick={getNextOrder}
      >
        {state.isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <CardIcon /> {t("button")}
          </>
        )}
      </motion.button>
    </Block>
  );
}
