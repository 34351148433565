import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQueryWithTokenWrapper from "../base-query";
import { IAutoPayoutItemResponse } from "./autoPayouts.types";

function transformAutoPayout(resp: IAutoPayoutItemResponse) {
  let transformedResp = resp;
  if (transformedResp.data.bank_profile) {
    transformedResp.data.bank_profile.fio_short = `${
      resp.data.bank_profile?.last_name
    }${
      resp.data.bank_profile?.middle_name
        ? ` ${resp.data.bank_profile.middle_name.slice(0, 1)}.`
        : ""
    } ${resp.data.bank_profile?.first_name.slice(0, 1)}.`;
  }
  if (transformedResp.data.bank_profile) {
    transformedResp.data.bank_profile.fio = `${
      resp.data.bank_profile?.last_name
    }${
      resp.data.bank_profile?.middle_name
        ? ` ${resp.data.bank_profile.middle_name}`
        : ""
    } ${resp.data.bank_profile?.first_name}`;
  }
  if (transformedResp.data.limit && transformedResp.data.limit?.total) {
    transformedResp.data.limit.remaining =
      +transformedResp.data.limit?.total - +transformedResp.data.limit?.used;
  }
  // transformedResp.data.state = "failed";
  // transformedResp.data.error_code = "SUSPENDED_FOR_SECURITY_REASON";
  // transformedResp.data.message =
  //   "Операция приостановлена. Требуется подтверждение.";

  return transformedResp;
}

export const autoPayoutsApi = createApi({
  reducerPath: "autoPayoutsApi",
  baseQuery: baseQueryWithTokenWrapper,
  endpoints: (builder) => ({
    createAutoPayout: builder.mutation<
      IAutoPayoutItemResponse,
      { payout_order_uuid: string }
    >({
      query: (body) => {
        return {
          url: `massmo/v1/auto_payouts`,
          method: "POST",
          body,
        };
      },
    }),
    getAutoPayout: builder.query<
      IAutoPayoutItemResponse,
      { id: string | number }
    >({
      query: ({ id }) => `massmo/v1/auto_payouts/${id}`,
      transformResponse: (resp: IAutoPayoutItemResponse) => {
        return transformAutoPayout(resp);
      },
    }),
    makeProcessingAutoPayout: builder.mutation<
      IAutoPayoutItemResponse,
      { id: number | string }
    >({
      query: ({ id }) => {
        return {
          url: `massmo/v1/auto_payouts/${id}/make_processing`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useCreateAutoPayoutMutation,
  useGetAutoPayoutQuery,
  useMakeProcessingAutoPayoutMutation,
} = autoPayoutsApi;
