import React from "react";
import OrderItemLoader from "../OrderItem/OrderItemLoader";

export default function OrdersPageListLoader() {
  return (
    <>
      <OrderItemLoader />
      <OrderItemLoader />
      <OrderItemLoader />
    </>
  );
}
