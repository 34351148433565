import React from "react";
import { useAppDispatch } from "../../store/hooks";
import { authApi } from "../../store/auth/auth.api";

export default function useClearCache() {
  const dispatch = useAppDispatch();

  function clearCache() {
    dispatch(authApi.util.resetApiState());
  }

  return clearCache;
}
