import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-06.svg";
import classNames from "classnames";
import { Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import useCopyToClipboard from "../../../utils/hooks/useCopyToClipboard";
import { motionButtonProps } from "../../../utils/styles/motionSettings";

interface ICopy {
  value: string;
}

export default function Copy(props: ICopy) {
  const [copiedValue, copyFn] = useCopyToClipboard();

  return (
    <motion.button
      {...motionButtonProps}
      onClick={() => copyFn(props.value)}
      className={classNames(styles.container)}
    >
      <Tooltip
        PopperProps={{ style: { zIndex: 3001 } }}
        open={Boolean(copiedValue)}
        title="Скопировано"
      >
        <CopyIcon />
      </Tooltip>
    </motion.button>
  );
}
