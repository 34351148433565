export function getFileNameParts(fullFileName: string) {
  const separator = ".";
  const parts = fullFileName?.split(separator);

  const startWithDot = fullFileName?.startsWith(separator);
  if (parts.length <= 1 || startWithDot) {
    return {
      name: fullFileName !== separator ? fullFileName : "",
      extension: "",
    };
  }

  const fileName = parts?.slice(0, parts?.length - 1).join(".");
  const extension = parts?.[parts?.length - 1]
    ? `.${parts?.[parts?.length - 1]}`
    : "";

  return {
    name: fileName,
    extension,
  };
}
