import React, { useEffect } from "react";

import styles from "./styles.module.scss";

import { motion } from "framer-motion";
import { motionButtonProps } from "../../utils/styles/motionSettings";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReservesBlock from "../OrderPage/ReservesBlock/ReservesBlock";
import StatusSwitcher from "./StatusSwitcher/StatusSwitcher";
import CancelBlock from "../OrderPage/OrderContent/CancelBlock/CancelBlock";
import classNames from "classnames";
import AmountBlock from "../OrderPage/AmountBlock/AmountBlock";
import RequisiteBlock from "../OrderPage/OrderContent/RequisiteBlock/RequisiteBlock";
import DetailsBlock from "../OrderPage/OrderContent/DetailsBlock/DetailsBlock";
import useGetAutoPayout from "../../utils/hooks/useGetAutoPayout";
import useGetBank from "../../utils/hooks/useGetBank";
import ModalCancel from "../../components/modals/ModalCancel/ModalCancel";
import useGoNextOrder from "../../utils/hooks/useGoNextOrder";

export default function AutoOrderPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { getNextOrder, state } = useGoNextOrder({});
  const { isSuccess } = state;
  const { data, isLoading } = useGetAutoPayout({
    id,
    stopPollingValues: ["failed", "completed"],
  });
  const bank = useGetBank({
    bankAlias: data?.data.payout_order.bank_name || "",
  });

  useEffect(() => {
    isSuccess && navigate("/");
  }, [isSuccess]);

  return (
    <>
      <ModalCancel
        orderData={data?.data.payout_order}
        isOpen={Boolean(searchParams.get("cancel_modal"))}
        onClose={() => {
          searchParams.delete("cancel_modal");
          setSearchParams(searchParams);
        }}
      />

      <ReservesBlock />

      <div className={styles.body}>
        <StatusSwitcher
          isLoading={isLoading}
          state={data?.data.state}
          data={data}
          isPayoutPage
        />

        {data?.data.state !== "confirmation" &&
          data?.data.error_code !== "SUSPENDED_FOR_SECURITY_REASON" &&
          (data?.data.state === "completed" ? (
            <motion.button
              onClick={getNextOrder}
              {...motionButtonProps}
              className={classNames(styles.handleButton)}
            >
              Перейти к новой заявке
            </motion.button>
          ) : (
            <motion.button
              onClick={() => navigate("/")}
              {...motionButtonProps}
              className={classNames(styles.handleButton)}
            >
              Оплатить вручную
            </motion.button>
          ))}

        {/* {data?.data && (
          <>
            <AmountBlock
              isProcessing={data?.data.state === "processing"}
              amount={data?.data?.amount || ""}
              currency={data?.data?.payout_order?.currency || ""}
            />
            <RequisiteBlock
              isProcessing={data?.data.state === "processing"}
              bank={{
                logo: bank?.logo,
                name: bank?.name,
              }}
              data={{
                requisites: data?.data.payout_order.requisites || "",
                sbp: data?.data.payout_order.sbp || false,
                fio: data?.data.payout_order.fio || "",
              }}
            />
            <DetailsBlock
              data={{
                created_at: data?.data.payout_order.created_at || "",
                uuid: data?.data.payout_order.uuid || "",
              }}
            />
          </>
        )} */}
      </div>

      {data?.data.state !== "completed" && (
        <CancelBlock
          onCancel={() => {
            searchParams.set("cancel_modal", "1");
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}
