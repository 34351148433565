import type { GetShortStringOptions } from './getShortString.types';

export function getShortString(str: string, options?: GetShortStringOptions): string {
  const { trimLength = 4, divider = '...' } = options || {};
  const { length } = str;

  const trimEnd = typeof options?.trimEnd == 'number' ? options.trimEnd : trimLength;

  if (!trimLength || length <= trimLength + trimEnd) {
    return str;
  }
  const start = str.slice(0, trimLength);
  const end = str.slice(length - trimEnd, length);
  return `${start}${divider}${end}`;
}
