import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  IBanksListResponse,
  IPayoutLimitsResponse,
} from "./payoutLimits.types";
import baseQueryWithTokenWrapper from "../base-query";

export const payoutLimitsApi = createApi({
  reducerPath: "payoutLimitsApi",
  baseQuery: baseQueryWithTokenWrapper,
  tagTypes: ["payoutLimits"],
  endpoints: (builder) => ({
    getPayoutLimits: builder.query<IPayoutLimitsResponse, void>({
      query: () => "massmo/v1/executor/payout_limits",
      providesTags: ["payoutLimits"],
    }),
    updatePayoutLimit: builder.mutation<
      any,
      { id: string | number; limit: number }
    >({
      query: (params) => ({
        url: `massmo/v1/executor/payout_limits/${params.id}`,
        method: "PATCH",
        body: {
          limit: params.limit,
        },
      }),
      invalidatesTags: ["payoutLimits"],
    }),
    createPayoutLimit: builder.mutation<
      any,
      { limit: number; bank_name: string }
    >({
      query: (body) => ({
        url: `massmo/v1/executor/payout_limits/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["payoutLimits"],
    }),
    getBanksList: builder.query<
      IBanksListResponse,
      {
        regionId?: string | number;
      }
    >({
      query: (params) => {
        let query = `massmo/v1/banks?`;
        return query;
      },
    }),
  }),
});

export const {
  useCreatePayoutLimitMutation,
  useGetPayoutLimitsQuery,
  useGetBanksListQuery,
  useUpdatePayoutLimitMutation,
} = payoutLimitsApi;
