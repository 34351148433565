const ordersPageText = {
  ordersPage: {
    title: "История выплат",
    search: "Поиск",
    empty: "Заявки не найдены",
    statusDescription: "Создана",
    remainingTime: "Осталось: {{remainingTime}} на загрузку чека",
    orderID: "ID заявки",
    receiptTitle: "Справка о платеже",
    openFile: "Открыть файл",
    time: {
      ch: "ч",
      min: "мин",
    },
    filters: {
      title: "Фильтр по заявкам",
      placeholder: "Не выбраны",
      selected: "Выбрано: {{count}}",
      dd: {
        title: "Параметры поиска",
        clear: "Сбросить все",
        submit: "Применить фильтры",
      },
      date: {
        title: "Дата получения",
        fieldYearPlaceholder: "ГГ",
        fieldMonthPlaceholder: "ММ",
        fieldDayPlaceholder: "ДД",
        fieldHoursPlaceholder: "ЧЧ",
        fieldMinutesPlaceholder: "ММ",
        fieldSecondsPlaceholder: "СС",
        okButtonLabel: "Применить",
        cancelButtonLabel: "Отмена",
        toolbarTitle: "Выберите дату и время",
        dateTo: "До",
        dateFrom: "От",
      },
      deal: {
        title: "Статус заявок",
        all: "Все сделки",
      },
      amount: {
        sum: "Сумма",
        eq: "Точное значение",
        range: "Диапазон",
        amountFrom: "Сумма, от",
        amountTo: "Сумма, до",
        sumPlaceholder: "Введите точную сумму",
      },
    },
    tags: {
      clear: "Сбросить все",
      filtersTitle: "Выбранные параметры поиска",
    },
  },
};

export default ordersPageText;
