import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IExecutor, IGetExecutorResponse } from "./executor.types";
import baseQueryWithTokenWrapper from "../base-query";

export const executorApi = createApi({
  reducerPath: "executorApi",
  baseQuery: baseQueryWithTokenWrapper,
  tagTypes: ["executor"],
  endpoints: (builder) => ({
    getExecutor: builder.query<IGetExecutorResponse, void>({
      query: () => "massmo/v1/executor",
      providesTags: ["executor"],
    }),
    changeExecutorState: builder.mutation<
      any,
      {
        state: string;
      }
    >({
      query: (params) => ({
        url: `massmo/v1/executor/change_state`,
        method: "POST",
        body: {
          state: params.state,
        },
      }),
      invalidatesTags: ["executor"],
    }),
    changeExecutor: builder.mutation<any, Partial<IExecutor>>({
      query: (body) => ({
        url: `massmo/v1/executor`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["executor"],
    }),
  }),
});

export const {
  useGetExecutorQuery,
  useChangeExecutorStateMutation,
  useChangeExecutorMutation,
} = executorApi;
