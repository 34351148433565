import { StyleType } from "../types/shared";

export function getColorFromType(type: StyleType) {
  switch (type) {
    case "danger":
      return "failed";
    case "warning":
      return "processing";
    default:
      return type;
  }
}
