import authReducer from "./auth/auth.slice";
import filesReducer from "./files/files.slice";
import uiReducer from "./ui/ui.slice";
import ordersReducer from "./orders/orders.slice";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authApi } from "./auth/auth.api";
import { userApi } from "./user/user.api";
import { ordersApi } from "./orders/orders.api";
import { executorApi } from "./executor/executor.api";
import { payoutLimitsApi } from "./payoutLimits/payoutLimits.api";
import { autoPayoutsApi } from "./autoPayouts/autoPayouts.api";

const combinedReducer: any = combineReducers({
  auth: authReducer,
  files: filesReducer,
  ui: uiReducer,
  orders: ordersReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [executorApi.reducerPath]: executorApi.reducer,
  [payoutLimitsApi.reducerPath]: payoutLimitsApi.reducer,
  [autoPayoutsApi.reducerPath]: autoPayoutsApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  //clear state on log out
  if (action.type === "authApi/resetApiState") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(ordersApi.middleware)
      .concat(executorApi.middleware)
      .concat(payoutLimitsApi.middleware)
      .concat(autoPayoutsApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
