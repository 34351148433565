import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithToken from "../base-query";

interface IUserResponse {
  data: {
    name: string;
    payouts_total_limit: string;
    roles: {
      id: number;
      name: string;
    }[];
    team: {
      id: number;
      name: string;
    };
  };
  errors: {};
  message: null;
  status: string;
  meta: {};
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithToken,
  endpoints: (builder) => ({
    getUserInfo: builder.query<IUserResponse, void>({
      query: () => `/massmo/v1/user/me`,
    }),
  }),
});

export const { useGetUserInfoQuery } = userApi;
