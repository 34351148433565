import React, { useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as ChevronIcon } from "@/assets/icons/chevron-down-01.svg";
import classNames from "classnames";
import { Skeleton } from "@mui/material";
import {
  useGetBanksListQuery,
  useGetPayoutLimitsQuery,
} from "@/store/payoutLimits/payoutLimits.api";
import { transformCurrencyValue } from "@/utils/helpers/transformCurrencyValue";
import { useTranslation } from "react-i18next";

export default function ReservesBlock() {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.reservesBlock",
  });
  const { data, isLoading } = useGetPayoutLimitsQuery();
  const { data: banksData, isLoading: isBanksLoading } = useGetBanksListQuery({
    regionId: 1,
  });
  const [isListVisible, setListVisible] = useState(false);

  const isShowVisible = data?.data?.length && data?.data?.length > 2;

  if (isLoading || isBanksLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.item}>
            <div className={styles.logo}>
              <Skeleton width={20} height={20} variant="circular" />
            </div>
            <div className={styles.info}>
              <div className={styles.amount}>
                <Skeleton />
              </div>
              <div className={styles.bank}>
                <Skeleton />
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.logo}>
              <Skeleton width={20} height={20} variant="circular" />
            </div>
            <div className={styles.info}>
              <div className={styles.amount}>
                <Skeleton />
              </div>
              <div className={styles.bank}>
                <Skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (data?.data.length === 0) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        {data?.data?.slice(0, isListVisible ? data.data.length : 2).map((i) => {
          let bank;
          if (banksData?.data) {
            bank = banksData.data.find((bank) => bank.alias === i.bank_name);
          }
          return (
            <div key={i.id} className={styles.item}>
              <div className={styles.logo}>
                {bank?.logo && <img src={bank?.logo} alt="logo" />}
              </div>
              <div className={styles.info}>
                <div className={styles.amount}>
                  {transformCurrencyValue(i.limit)}{" "}
                  {bank?.region?.currency?.toUpperCase()}
                </div>
                <div className={styles.bank}>{bank?.name}</div>
              </div>
            </div>
          );
        })}
      </div>
      {isShowVisible && (
        <button
          className={classNames(styles.show, {
            [styles.active]: isListVisible,
          })}
          onClick={() => setListVisible((state) => !state)}
        >
          {isListVisible ? t("collapse") : t("expand")} <ChevronIcon />
        </button>
      )}
    </div>
  );
}
