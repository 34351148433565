import React from "react";

import styles from "./styles.module.scss";

import { transformCurrencyValue } from "../../../utils/helpers/transformCurrencyValue";
import Copy from "../../../components/shared/Copy/Copy";
import { useTranslation } from "react-i18next";

export default function AmountBlock({
  amount,
  currency,
  isProcessing,
}: {
  amount: string;
  currency: string;
  isProcessing: boolean;
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.amountBlock",
  });
  return (
    <section className={styles.order}>
      {!isProcessing && <div className={styles.left}>{t("title")}</div>}
      <div className={styles.right}>
        {`${transformCurrencyValue(+amount)} ${currency.toUpperCase()}`}
        {isProcessing && <Copy value={amount} />}
      </div>
    </section>
  );
}
