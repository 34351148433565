import React, { useState, useRef } from "react";
import MaskedInput, { Mask } from "react-text-mask";

import styles from "./styles.module.scss";

import classNames from "classnames";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";

export interface IInput {
  type?: "error" | "empty" | "success";
  size?: "sm";
  mode?: "light" | "search";
  withBorder?: boolean;
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  inputAttributes?: any;
  borderType?: "md";
  mask?: Mask;
  containerStyle?: any;
  currencyInputProps?: CurrencyInputProps;
  containerClassName?: string;
}

export default function Input(props: IInput) {
  const {
    type,
    leftElement,
    rightElement,
    inputAttributes,
    mode,
    size,
    borderType,
    mask,
    currencyInputProps,
    withBorder = true,
    containerStyle,
    containerClassName,
    ...rest
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  function focusOnInput() {
    inputRef.current?.focus();
  }

  function onFocus() {
    setIsFocused(true);
  }
  return (
    <div
      onClick={focusOnInput}
      className={classNames(styles.container, containerClassName, {
        [styles.active]: withBorder && isFocused,
        [styles.error]: type === "error",
        [styles.empty]: type === "empty",
        [styles.success]: type === "success",

        [styles.light]: mode === "light",
        [styles.search]: mode === "search",
        [styles.sm]: size === "sm",

        [styles.borderMd]: borderType === "md",
        [containerStyle]: Boolean(containerStyle),
      })}
    >
      {leftElement && <div className={styles.leftElement}>{leftElement}</div>}
      {currencyInputProps && (
        <CurrencyInput
          ref={inputRef}
          className={styles.input}
          onFocus={onFocus}
          onBlur={() => setIsFocused(false)}
          {...currencyInputProps}
          {...inputAttributes}
          {...rest}
        />
      )}
      {mask && (
        <MaskedInput
          mask={mask}
          className={styles.input}
          onFocus={onFocus}
          onBlur={() => setIsFocused(false)}
          itemRef={inputRef}
          {...inputAttributes}
          {...rest}
        />
      )}
      {!mask && !currencyInputProps && (
        <input
          ref={inputRef}
          className={styles.input}
          onFocus={onFocus}
          onBlur={() => setIsFocused(false)}
          {...inputAttributes}
          {...rest}
        />
      )}

      {rightElement && (
        <div
          className={classNames(styles.rightElement, {
            [styles.date]: inputRef?.current?.type === "date",
          })}
        >
          {rightElement}
        </div>
      )}
    </div>
  );
}
