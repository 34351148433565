import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Text from "@/components/typography/Text/Text";

export default function NotFoundPage() {
  const { t } = useTranslation(undefined, { keyPrefix: "notFoundPage" });
  return (
    <>
      <h1>404 - {t("title")}</h1>
      <Link to="/">
        <Text type="accent"> {t("link")}</Text>
      </Link>
    </>
  );
}
