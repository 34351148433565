import React from "react";

import styles from "./styles.module.scss";

import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import Status from "../../shared/Status/Status";
import ModalContainer from "../ModalBase/ModalContainer/ModalContainer";
import Button from "../../controls/Button/Button";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-off.svg";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { motionButtonProps } from "../../../utils/styles/motionSettings";
import { useTranslation } from "react-i18next";

export default function ModalEmptyNext(props: IModalBase) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.modalEmptyNext",
  });
  const navigate = useNavigate();
  return (
    <ModalBase {...props}>
      <ModalContainer>
        <Status
          icon={<EyeIcon />}
          type="accent"
          title={t("title")}
          description={t("description")}
        />
        <div className={styles.buttons}>
          <motion.div className={styles.buttonWrapper} {...motionButtonProps}>
            <Button
              size="lg"
              type="muted"
              onClick={() => {
                props.onClose();
                navigate("/profile");
              }}
            >
              {t("params")}
            </Button>
          </motion.div>
          <motion.div className={styles.buttonWrapper} {...motionButtonProps}>
            <Button size="lg" onClick={props.onClose}>
              {t("close")}
            </Button>
          </motion.div>
        </div>
      </ModalContainer>
    </ModalBase>
  );
}
