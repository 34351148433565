import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface CounterState {
  file: File | null;
}

const initialState: CounterState = {
  file: null,
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<File>) => {
      state.file = action.payload;
    },
    deleteFile: (state) => {
      state.file = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFile, deleteFile } = filesSlice.actions;
export const selectFile = (state: RootState) => state.files?.file;

export default filesSlice.reducer;
