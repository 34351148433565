export function hexToRgbByCssVar(cssVar: string) {
  const bodyStyles = window.getComputedStyle(document.body);
  const value = bodyStyles.getPropertyValue(cssVar);
  return hexToRgb(value);
}

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
}
