const orderStatesText = {
  orderStates: {
    completed: {
      title: "Order Paid",
      description: "Payment has been verified",
    },
    canceled: {
      title: "Order Canceled",
      description: "You have canceled the order",
    },
    expired: {
      title: "Time Expired",
      description: "Payment time has expired",
    },
    verification_failed: {
      title: "Not Paid",
      description: "Check the payment receipt",
    },
    verification: {
      title: "Payment Verification",
      description: "The system is scanning the payment receipt",
    },
    manual_verification: {
      title: "Manual Verification by Administration",
      description: "Payment is being verified",
    },
    pending: {
      title: "Awaiting Payment",
    },
    paid: {
      title: "Awaiting Receipt Upload",
    },
    default: {
      title: "Error: Order Not Recognized",
    },
  },
};

export default orderStatesText;
