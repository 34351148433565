const ordersPageText = {
  ordersPage: {
    title: "Payment History",
    search: "Search",
    empty: "No requests found",
    statusDescription: "Created",
    remainingTime: "Remaining time: {{remainingTime}} to upload the receipt",
    orderID: "Request ID",
    receiptTitle: "Payment receipt",
    openFile: "Open file",
    time: {
      ch: "h",
      min: "min",
    },
    filters: {
      title: "Filter by requests",
      placeholder: "Not selected",
      selected: "Selected: {{count}}",
      dd: {
        title: "Search parameters",
        clear: "Clear all",
        submit: "Apply filters",
      },
      date: {
        title: "Date received",
        fieldYearPlaceholder: "YY",
        fieldMonthPlaceholder: "MM",
        fieldDayPlaceholder: "DD",
        fieldHoursPlaceholder: "HH",
        fieldMinutesPlaceholder: "MM",
        fieldSecondsPlaceholder: "SS",
        okButtonLabel: "Apply",
        cancelButtonLabel: "Cancel",
        toolbarTitle: "Select date and time",
        dateTo: "To",
        dateFrom: "From",
      },
      deal: {
        title: "Request status",
        all: "All deals",
      },
      amount: {
        sum: "Amount",
        eq: "Exact value",
        range: "Range",
        amountFrom: "Amount, from",
        amountTo: "Amount, to",
        sumPlaceholder: "Enter exact amount",
      },
    },
    tags: {
      clear: "Clear all",
      filtersTitle: "Selected search parameters",
    },
  },
};

export default ordersPageText;
