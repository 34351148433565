const profilePageText = {
  profilePage: {
    amount: {
      title: "Available payment volume",
    },
    title: "Account management",
    exit: "Exit",
    reserves: {
      title: "Reserves",
      rules: {
        required: "Field is not filled",
        min: `Enter an amount greater than {{count}}`,
      },
      editText: {
        error: "Error",
        saved: "Reserve saved",
        placeholder: "Enter a new value",
      },
      bank: "Bank",
      add: {
        onSubmit: {
          success: {
            title: "Success",
            description: "Reserve added successfully",
          },
          error: {
            title: "Error",
            description: "An error occurred while creating a reserve",
          },
        },
        bank: {
          placeholder: "Select a bank",
          required: "Bank is not selected",
        },
        reserve: {
          title: "Reserve",
          required: "Reserve is not filled",
          min: `Enter an amount greater than {{count}}`,
        },
        submit: "Add reserve",
        addNew: "Add a new reserve",
      },
    },
    token: {
      title: "Token settings",
      disable: "Disable",
      enable: "Enable",
      enabled: {
        switch: "Token is enabled",
        state: "You receive payment requests",
        description:
          "When the token is disabled, active requests will be transferred to another executor",
      },
      disabled: {
        switch: "Token is disabled",
        state: "You do not receive payment requests",
        description: "Enable the token to receive new payment requests",
      },
    },
    sbp: {
      disable: "Disable",
      enable: "Enable",
      enabled: {
        switch: "SBP payments",
        description: "Enable to accept SBP payment requests",
      },
      disabled: {
        switch: "SBP payments",
        description: "Disable to not accept SBP payment requests",
      },
    },
    c2c: {
      disable: "Disable",
      enable: "Enable",
      enabled: {
        switch: "Card-to-card transfers",
        description: "Enable to accept payment requests by card number",
      },
      disabled: {
        switch: "Card-to-card transfers",
        description: "Disable to not accept payment requests by card number",
      },
    },
    account: {
      disable: "Disable",
      enable: "Enable",
      switch: "Transfers by account number",
      description: "Payment requests by account number for Sberbank clients",
    },
  },
};

export default profilePageText;
