import React from "react";

import styles from "./styles.module.scss";

import classNames from "classnames";
import Input, { IInput } from "../Input/Input";

interface IInputWithLabel extends IInput {
  label?: string;
  error?: string;
}

export default function InputWithLabel(props: IInputWithLabel) {
  const { label, error, ...rest } = props;
  return (
    <div className={classNames(styles.container)}>
      {label && (
        <label
          className={classNames(styles.label, {
            [styles.error]: props.type === "error",
            [styles.sm]: props.size === "sm",
          })}
        >
          {label}
        </label>
      )}
      <Input {...rest} />
      {error && (
        <p
          className={classNames(styles.errorText, {
            [styles.error]: props.type === "error",
            [styles.sm]: props.size === "sm",
          })}
        >
          {error}
        </p>
      )}
    </div>
  );
}
