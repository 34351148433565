import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";
import { SizeType, StyleType } from "../../../utils/types/shared";
export interface IAvatar {
  mode?: "square" | "squareOutline" | "bgDark" | "circle";
  children: React.ReactNode;
  type?: StyleType;
  size?: SizeType | "xl";
  badge?: string | React.ReactNode | null;
  className?: string;
}

export default function Avatar(props: IAvatar) {
  const {
    type = "accent",
    mode = "circle",
    children,
    size,
    badge,
    className,
  } = props;
  return (
    <div
      className={classNames(styles.container, className, {
        [styles.loading]: type === "loading",
        [styles.accent]: type === "accent",
        [styles.primary]: type === "primary",
        [styles.success]: type === "success",
        [styles.danger]: type === "danger",
        [styles.warning]: type === "warning",
        [styles.purple]: type === "purple",

        [styles.square]: mode === "square" || mode === "squareOutline",
        [styles.squareOutline]: mode === "squareOutline",
        [styles.bgDark]: mode === "bgDark",

        [styles.xs]: size === "xs",
        [styles.sm]: size === "sm",
        [styles.md]: size === "md",
        [styles.lg]: size === "lg",
        [styles.xl]: size === "xl",
      })}
    >
      {children}
    </div>
  );
}
