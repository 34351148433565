import React from "react";

import styles from "./styles.module.scss";

import Avatar from "../../../components/shared/Avatar/Avatar";
import { Skeleton } from "@mui/material";

export default function OrderItemLoader() {
  return (
    <div className={styles.item}>
      <div className={styles.itemTop}>
        <div className={styles.itemStatus}>
          <Avatar type={"loading"} mode="square">
            <Skeleton width={24} height={24} variant="circular" />
          </Avatar>
          <div className={styles.textBlock}>
            <div className={styles.statusTitle}>
              <Skeleton width={118} />
            </div>
            <div className={styles.statusDescription}>
              <Skeleton width={156} />
            </div>
          </div>
        </div>
        <div className={styles.amount}>
          <Skeleton width={71} />
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.bottom}>
          <div className={styles.requisites}>
            <div className={styles.bank}>
              <Skeleton width={20} height={20} variant="rounded" />
              <span>
                <Skeleton width={42} />
              </span>
            </div>
            <div className={styles.paySystem}>
              <Skeleton width={160} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
