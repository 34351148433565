import React, { ReactNode } from "react";

import styles from "./styles.module.scss";

import Avatar from "../Avatar/Avatar";
import { StyleType } from "../../../utils/types/shared";

export default function Status({
  description,
  icon,
  title,
  type = "accent",
  children,
}: {
  icon?: ReactNode;
  title: ReactNode;
  description: ReactNode;
  type?: StyleType;
  children?: ReactNode;
}) {
  return (
    <div className={styles.status}>
      {icon && (
        <Avatar size="md" className={styles.avatar} type={type}>
          {icon}
        </Avatar>
      )}
      <div className={styles.textBlock}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>
      </div>
      {children}
    </div>
  );
}
