import React from "react";

import styles from "../styles.module.scss";

import PercentageCircle from "../../PercentageCircle/PercentageCircle";
import { useCountdown } from "../../../../utils/hooks/useCountDown";
import { useTranslation } from "react-i18next";

export default function TimerContent({
  description,
  countDownStart,
  initialDate,
}: {
  description?: string;
  countDownStart: number;
  initialDate: number;
}) {
  const { t } = useTranslation(undefined, { keyPrefix: "orderPage.timer" });
  const [days, hours, minutes, seconds, countDown] = useCountdown(initialDate);
  const range = initialDate - countDownStart;
  const percentage = Math.ceil(countDown / (range / 100));
  if (countDown <= 0) {
    return (
      <div className={styles.timer}>
        <PercentageCircle percentage={0} />
        <div className={styles.timerInfo}>
          {description && (
            <p className={styles.timerDescription}>{description}</p>
          )}
          <p className={styles.timerValue}>{t("timeIsOver")}</p>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.timer}>
      <PercentageCircle percentage={percentage} />
      <div className={styles.timerInfo}>
        <>
          {description && (
            <p className={styles.timerDescription}>{description}</p>
          )}
          <p className={styles.timerValue}>
            {minutes} {t("min")} {seconds < 10 ? "0" : ""}
            {seconds} {t("sec")}
          </p>
        </>
      </div>
    </div>
  );
}
