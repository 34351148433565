import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as UserIcon } from "../../../../assets/icons/user-03.svg";
import { ReactComponent as FioIcon } from "../../../../assets/icons/fio.svg";
import { ReactComponent as CardIcon } from "../../../../assets/icons/credit-card-02.svg";
import { ReactComponent as RubIcon } from "../../../../assets/icons/currency-ruble-circle.svg";
import { ReactComponent as PercentIcon } from "../../../../assets/icons/percent-02.svg";
import { IAutoPayoutItemResponse } from "../../../../store/autoPayouts/autoPayouts.types";
import { transformCurrencyValue } from "../../../../utils/helpers/transformCurrencyValue";
import { useNavigate } from "react-router-dom";
import { useMakeProcessingAutoPayoutMutation } from "../../../../store/autoPayouts/autoPayouts.api";
import showToast from "../../../../utils/helpers/toast/showToast";
import Button from "../../../controls/Button/Button";
import Text from "../../../typography/Text/Text";
import InfoItem from "./InfoItem/InfoItem";
import Bar from "./Bar/Bar";

export default function Confirmation({
  data,
  isUninitializedAutoPayout,
  refetchAutoPayout,
}: {
  data: IAutoPayoutItemResponse | undefined;
  refetchAutoPayout?: () => void;
  isUninitializedAutoPayout?: boolean;
}) {
  const navigate = useNavigate();

  const [makeProcessing, { error, isLoading }] =
    useMakeProcessingAutoPayoutMutation();

  async function onSubmit() {
    if (!data?.data.id) {
      return showToast({
        type: "error",
        value: "Ошибка",
        description: "Не найден id перевода",
      });
    }
    try {
      await makeProcessing({ id: data?.data.id }).unwrap();
      !isUninitializedAutoPayout && refetchAutoPayout && refetchAutoPayout();
      navigate(`/auto-order/${data.data.id}`);
    } catch {
      return showToast({
        type: "error",
        value: "Ошибка",
        description: "Не удалось подтвердить перевод",
      });
    }
  }
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Подтверждение перевода</h2>
      <div className={styles.body}>
        <div className={styles.account}>
          <div className={styles.left}>
            <div className={styles.name}>Карта</div>
            <div className={styles.card}>
              {data?.data.material?.card_number
                ? `** ${data?.data.material?.card_number.slice(-4)}`
                : "-"}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.balance}>
              {data?.data.material?.balance
                ? `${transformCurrencyValue(
                    +data?.data.material?.balance
                  )} ${data.data.payout_order.currency?.toUpperCase()}`
                : "-"}
            </div>
          </div>
        </div>
        <InfoItem
          title="Банковский профиль для выплаты"
          leftValue={data?.data.bank_profile?.fio_short}
          leftDescription={`#${data?.data?.bank_profile?.id} ${data?.data?.bank_profile?.phone_number}`}
          icon={<UserIcon />}
        />
        <InfoItem
          title="ФИО получателя"
          leftValue={data?.data?.recipient_info?.name}
          icon={<FioIcon />}
        />

        <InfoItem
          title="Номер карты получателя"
          leftValue={data?.data?.recipient_info?.card}
          icon={<CardIcon />}
        />

        <InfoItem
          title="Сумма перевода"
          leftValue={
            data?.data.amount
              ? `${transformCurrencyValue(
                  +data?.data.amount
                )} ${data.data?.payout_order?.currency?.toUpperCase()}`
              : "-"
          }
          icon={<RubIcon />}
        />

        <InfoItem
          title="Комиссия"
          leftValue={
            <Text type="danger">
              {data?.data.commission
                ? transformCurrencyValue(+data?.data.commission)
                : "-"}{" "}
              {data?.data?.payout_order?.currency?.toUpperCase()}
            </Text>
          }
          icon={<PercentIcon />}
        />

        {data?.data.limit?.total && (
          <div className={styles.limit}>
            <h3 className={styles.limitTitle}>Доступный лимит для переводов</h3>
            <div className={styles.limitBox}>
              <div className={styles.top}>
                <b>{transformCurrencyValue(+data?.data.limit?.remaining)}</b> /{" "}
                {transformCurrencyValue(+data?.data.limit?.total)}{" "}
                {data?.data?.payout_order?.currency?.toUpperCase()}
              </div>
              <Bar
                percent={
                  +data.data.limit.remaining / (+data.data.limit.total / 100)
                }
              />
            </div>
          </div>
        )}
      </div>
      {error && <Text type="danger">{JSON.stringify(error)}</Text>}
      <Button
        loading={isLoading}
        onClick={onSubmit}
        size="lg"
        className={styles.button}
      >
        Перевести{" "}
        {data?.data.amount ? transformCurrencyValue(+data?.data.amount) : "-"}{" "}
        {data?.data?.payout_order?.currency?.toUpperCase()}
      </Button>
    </div>
  );
}
