import type { MouseEvent } from 'react';
import type { DropEvent } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { checkAreFilesEqual, getFileExtensionByType } from '@/utils/helpers';

import type { FileInputDropProps } from './FileInputDrop.types';

import s from './FileInputDrop.module.scss';
import FileInputPlaceholder from "./FileInputPlaceholder/FileInputPlaceholder";
import UploadedFile from "./UploadedFile/UploadedFile";
import LoadMore from "./LoadMore/LoadMore";
import {CircularProgress} from "@mui/material";

function FileInputDrop({
  className,
  value,
  setValue,
  acceptedFileTypes = [],
  maxFiles = 2,
  placeholder,
  isLoading
}: FileInputDropProps) {
  const acceptFormats = acceptedFileTypes.reduce((acc: Record<string, string[]>, rec: string) => {
    const extension = getFileExtensionByType(rec);
    if (extension) {
      acc[rec] = [extension];
    }
    return acc;
  }, {});

  const isMaxFilesReached = value.length >= maxFiles;

  const isDisabled = isMaxFilesReached || isLoading;
  const isRemoveDisabled = isLoading;

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    accept: acceptFormats,
    disabled: isDisabled,
  });

  function onDropAccepted(files: File[], _event: DropEvent) {
    if (isDisabled) {
      return;
    }

    setValue((prev) => {
      const uniqFiles = files.filter((file) => !prev.some((it) => checkAreFilesEqual(it, file)));
      const availableLength = maxFiles - prev.length;
      return [...prev, ...uniqFiles.slice(0, availableLength)];
    });
  }

  function onDelete(e: MouseEvent, file: File) {
    if (isRemoveDisabled) {
      return;
    }
    e.stopPropagation();
    setValue((prev) => prev.filter((it) => it !== file));
  }

  return (
      <div className={classNames(s.wrapper, className)}>
        {isLoading
            ? <CircularProgress className={s.wrapper__loading} />
            : <>
                {value.map((it) => {
                  const fileKey = `${it.name}-${it.size}-${it.type}-${it.lastModified}`;
                  return (
                    <UploadedFile
                      key={fileKey}
                      file={it}
                      onDelete={(e) => onDelete(e, it)}
                      disabled={isRemoveDisabled}
                    />
                  );
                })}

                {!isMaxFilesReached ? (
                    <div
                        {...getRootProps()}
                        className={classNames(s.wrapper__drop, {
                          [s.wrapper__drop_full]: !value.length
                        })}
                    >
                      {!value.length ? <FileInputPlaceholder text={placeholder} /> : <LoadMore />}
                      <input {...getInputProps()} />
                    </div>
                ) : null}
            </>
        }
      </div>
  );
}

export default FileInputDrop;
