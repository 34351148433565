import classNames from "classnames";

import type { LoadMoreProps } from "./LoadMore.types";

import s from "./LoadMore.module.scss";

import { ReactComponent as PlusCircleIcon } from "@/assets/icons/plus-circle.svg";

import { useTranslation } from "react-i18next";

function LoadMore({ className }: LoadMoreProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.fileInputDrop",
  });

  return (
    <div className={classNames(s.load, className)}>
      <div className={s.interactive}>
        <PlusCircleIcon className={s.interactive__image} />
      </div>
      <div className={s.info}>
        <p className={s.info__text}>{t("add")}</p>
      </div>
    </div>
  );
}

export default LoadMore;
