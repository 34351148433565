import {useMemo } from 'react';
import classNames from 'classnames';

import { ReactComponent as FileIcon } from "@/assets/icons/file.svg";
import { ReactComponent as ImageIcon } from "@/assets/icons/Image.svg";
import { ReactComponent as FilePdfIcon } from "@/assets/icons/file-pdf.svg";
import type { FileType } from '@/utils/types';

import type { FileImageOptions, FileImageProps } from './FileImage.types';

import s from './FileImage.module.scss';

function FileImage({ className, fileType, size = 'md', imageClass }: FileImageProps) {
  const {Icon, wrapperClass} = useMemo((): FileImageOptions => {
    const imageOptionsMap: Record<FileType | string, FileImageOptions> = {
      'application/pdf': {
        Icon: FilePdfIcon,
        wrapperClass: s.wrapper_pdf
      },
      'image/png': {
        Icon: ImageIcon,
      },
      'image/jpg': {
        Icon: ImageIcon,
      },
      'image/jpeg': {
        Icon: ImageIcon,
      },
    };

    const imageOptions = imageOptionsMap[fileType];

    if (imageOptions) {
      return imageOptions;
    }
    return {
      Icon: FileIcon,
    };
  }, [fileType]);

  return (
    <div
      className={classNames(s.wrapper, className, wrapperClass, {
        [s.wrapper_md]: size === 'md',
      })}
    >
      <Icon className={classNames(s.wrapper__image, imageClass)} />
    </div>
  );
}

export default FileImage;
