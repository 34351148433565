import React from "react";

import styles from "./styles.module.scss";

import { getBarColor } from "../../../../../utils/helpers/progressBar";

export default function Bar(props: { percent: string | number }) {
  return (
    <div className={styles.conversion}>
      <div className={styles.barBg}>
        <div
          style={{
            height: "100%",
            width: +props.percent > 10 ? `${+props.percent}%` : 10,
            background: getBarColor("accent"),
            borderRadius: 5,
            content: "",
          }}
        />
      </div>
    </div>
  );
}
