import React from "react";

import styles from "./styles.module.scss";

export default function Loader() {
  return (
    <>
      <span className={styles.loader}></span>
    </>
  );
}
