import React, { useState } from "react";

import styles from "./styles.module.scss";
import filtersStyles from "../styles.module.scss";

import { ReactComponent as WalletIcon } from "../../../../assets/icons/wallet-02.svg";
import classNames from "classnames";
import Text from "../../../typography/Text/Text";
import Input from "../../../controls/Input/Input";
import { IFilterItem } from "../../../../utils/types/shared";
import { useTranslation } from "react-i18next";

export default function FilterAmount({
  formState,
  setFormState,
  isHiddenFields,
}: IFilterItem) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "ordersPage.filters.amount",
  });
  const [isRangeMode, setIsRangeMode] = useState(true);

  function clearAmountFields() {
    setFormState({ ...formState, amount: "", amountFrom: "", amountTo: "" });
  }
  function openRangeMode() {
    clearAmountFields();
    setIsRangeMode(true);
  }
  function openAmountMode() {
    clearAmountFields();
    setIsRangeMode(false);
  }
  function onAmountEqChange(value?: string) {
    setFormState({ ...formState, amount: value });
  }
  function onAmountFromChange(value?: string) {
    setFormState({ ...formState, amountFrom: value });
  }
  function onAmountToChange(value?: string) {
    setFormState({ ...formState, amountTo: value });
  }
  return (
    <div
      className={classNames(filtersStyles.item, {
        [filtersStyles.hidden]: isHiddenFields,
      })}
    >
      <div className={styles.headContainer}>
        <div className={filtersStyles.itemHead}>
          <WalletIcon />
          <div>{t("sum")}:</div>
        </div>
        <div className={styles.togglerBox}>
          <button onClick={openAmountMode}>
            <Text type={!isRangeMode ? "accent" : "muted"}>{t("eq")}</Text>
          </button>{" "}
          /{" "}
          <button onClick={openRangeMode}>
            <Text type={isRangeMode ? "accent" : "muted"}>{t("range")}</Text>
          </button>
        </div>
      </div>
      {isRangeMode ? (
        <div className={styles.grid}>
          <Input
            size="sm"
            containerStyle={styles.inputContainer}
            currencyInputProps={{
              value: formState.amountFrom,
              onValueChange: onAmountFromChange,
              prefix: "RUB ",
              placeholder: t("amountFrom"),
            }}
          />
          <Input
            size="sm"
            containerStyle={styles.inputContainer}
            currencyInputProps={{
              value: formState.amountTo,
              onValueChange: onAmountToChange,
              prefix: "RUB ",
              placeholder: t("amountTo"),
            }}
          />
        </div>
      ) : (
        <Input
          size="sm"
          containerStyle={styles.inputContainer}
          currencyInputProps={{
            value: formState.amount,
            onValueChange: onAmountEqChange,
            prefix: "RUB ",
            placeholder: t("sumPlaceholder"),
          }}
        />
      )}
    </div>
  );
}
