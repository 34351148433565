import React, { useState } from "react";

import styles from "./styles.module.scss";

import Text from "../../typography/Text/Text";
import classNames from "classnames";
import SelectButton from "../../controls/SelectButton/SelectButton";

import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import { motion } from "framer-motion";
import { ReactComponent as SwitchIcon } from "../../../assets/icons/switch-horizontal-01.svg";
import { ReactComponent as CardIcon } from "../../../assets/icons/credit-card-x.svg";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert-hexagon.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
  ordersApi,
  useCancelOrderMutation,
} from "../../../store/orders/orders.api";
import { useChangeExecutorStateMutation } from "../../../store/executor/executor.api";
import { motionButtonProps } from "../../../utils/styles/motionSettings";
import { IOrderItem } from "../../../store/orders/orders.types";
import { useAppDispatch } from "../../../store/hooks";
import { useTranslation } from "react-i18next";

interface IModalCancel extends IModalBase {
  orderData: IOrderItem | undefined;
}

export default function ModalCancel({ orderData, ...rest }: IModalCancel) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.modalCancel",
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [cancelReason, setCancelReason] = useState("");
  const [cancelOrder, { isLoading: cancelLoading, error: cancelError }] =
    useCancelOrderMutation();
  const [changeExecutorState, { isLoading: changeLoading }] =
    useChangeExecutorStateMutation();
  let customError = "";

  if (
    // @ts-ignore
    cancelError?.data?.message ===
    "Event 'cancel' cannot transition from 'verification'."
  ) {
    customError = t("errors.verification");
  }

  function onRequestConfirmed() {
    dispatch(ordersApi.util.resetApiState());
    searchParams.delete("cancel_modal");
    setSearchParams(searchParams);
  }

  async function onSubmit() {
    if (!cancelReason || !orderData?.id) {
      return;
    }
    if (cancelReason === "blocked") {
      try {
        await cancelOrder({ id: orderData.id }).unwrap();
        onRequestConfirmed();
        navigate("/");
      } catch (e) {}
      return;
    }
    await changeExecutorState({ state: "disabled" });
    onRequestConfirmed();
    return;
  }
  return (
    <ModalBase {...rest}>
      <div className={styles.container}>
        <div className={styles.content}>
          <section>
            <h2 className={styles.title}>{t("title")}</h2>
            <p className={styles.subTitle}>{t("subTitle")}</p>
          </section>
          <section className={styles.variants}>
            <SelectButton
              icon={<SwitchIcon />}
              text={t("blocked.title")}
              type="danger"
              isActive={cancelReason === "blocked"}
              onClick={() => setCancelReason("blocked")}
            />
            <SelectButton
              icon={<CardIcon />}
              text={t("troubles.title")}
              type="danger"
              isActive={cancelReason === "troubles"}
              onClick={() => setCancelReason("troubles")}
            />
          </section>
          {cancelReason === "troubles" && (
            <section className={styles.notice}>
              <AlertIcon />
              <p>{t("troubles.notice")}</p>
            </section>
          )}

          {(cancelError || customError) && (
            <Text type="danger">
              {customError ? customError : JSON.stringify(cancelError)}
            </Text>
          )}
        </div>
        <motion.button
          {...motionButtonProps}
          className={classNames(styles.submit)}
          disabled={!Boolean(cancelReason) || changeLoading || cancelLoading}
          onClick={onSubmit}
        >
          {changeLoading || cancelLoading ? (
            <CircularProgress size={"20"} />
          ) : (
            t("submit")
          )}
        </motion.button>
      </div>
    </ModalBase>
  );
}
