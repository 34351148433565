import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import profileStyles from "../styles.module.scss";

import { Skeleton, Tooltip } from "@mui/material";
import { useAppDispatch } from "@/store/hooks";
import {
  useChangeExecutorMutation,
  useGetExecutorQuery,
} from "@/store/executor/executor.api";
import { ordersApi } from "@/store/orders/orders.api";
import { useTranslation } from "react-i18next";
import Text from "@/components/typography/Text/Text";
import AppSwitch from "@/components/shared/AppSwitch/AppSwitch";
import classNames from "classnames";

export default function CashInBlock() {
  const { t } = useTranslation(undefined, { keyPrefix: "profilePage.cashIn" });
  const dispatch = useAppDispatch();
  const [isEnabled, setEnabled] = useState(true);
  const { data: executorData, isLoading: isExecutorLoading } =
    useGetExecutorQuery();
  const [changeExecutor] = useChangeExecutorMutation();

  const handleChange = async () => {
    setEnabled((state) => !state);
    if (executorData?.data?.state) {
      try {
        await changeExecutor({
          cashin_enabled: !executorData.data.cashin_enabled,
        }).unwrap();
        dispatch(ordersApi.util.resetApiState());
      } catch {}
    }
  };

  useEffect(() => {
    if (executorData?.data?.state) {
      setEnabled(executorData?.data?.cashin_enabled);
    }
  }, [executorData]);

  function getTokenSwitchText() {
    if (isExecutorLoading) {
      return <Skeleton height={19} width={128} />;
    }
    return (
      <Text type={executorData?.data?.cashin_enabled ? "accent" : "muted"}>
        {t("switch")}
      </Text>
    );
  }

  function getTokenDescription() {
    if (isExecutorLoading) {
      return <Skeleton width={"100%"} height={42} />;
    }
    return t("description");
  }
  return (
    <div className={classNames(profileStyles.paper, styles.container)}>
      <div className={styles.tokenSwitchContainer}>
        <Tooltip title={isEnabled ? t("disable") : t("enable")}>
          <AppSwitch
            disabled={isExecutorLoading}
            checked={isEnabled}
            onChange={handleChange}
          />
        </Tooltip>
        <p className={styles.tokenSwitchText}>{getTokenSwitchText()}</p>
      </div>
      <p className={styles.tokenDescription}>{getTokenDescription()}</p>
    </div>
  );
}
