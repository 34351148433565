import React, { Fragment, useEffect } from "react";

import styles from "./styles.module.scss";

import Input from "@/components/controls/Input/Input";
import Text from "@/components/typography/Text/Text";
import Button from "@/components/controls/Button/Button";
import Block from "@/components/shared/Block/Block";
import ErrorParser from "@/components/shared/ErrorParser/ErrorParser";
import { ReactComponent as XIcon } from "@/assets/icons/x-close.svg";
import { ReactComponent as PlusIcon } from "@/assets/icons/plus.svg";
import { ReactComponent as AlertIcon } from "@/assets/icons/alert-circle.svg";
import { ReactComponent as CheckIcon } from "@/assets/icons/check.svg";
import { IProofsProps, Link } from "../Proofs";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ILinksProps extends Pick<IProofsProps, "data"> {
  links: Link[];
  setLinks: React.Dispatch<React.SetStateAction<Link[]>>;
  isLoading?: boolean;
  error: any;
}

const Links: React.FC<ILinksProps> = ({
  links,
  setLinks,
  isLoading,
  data,
  error,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "orderPage.links" });

  const handleAddLink = () => {
    setLinks((prevLinks) => [...prevLinks, { value: "", error: undefined }]);
  };

  const handleRemoveLink = (index: number) => {
    setLinks((prevLinks) => prevLinks.filter((link, i) => i !== index));
  };

  const handleInputChange = (index: number, value: string) => {
    setLinks((prevLinks) =>
      prevLinks.map((link, i) =>
        i === index
          ? {
              value,
              error: isValidLink(value) ? undefined : t("invalidLink"),
            }
          : link
      )
    );
  };

  const isValidLink = (link: string) => {
    const regex = /^(http|https):\/\/[^ "]+$/;
    return regex.test(link);
  };

  useEffect(() => {
    if (data.cashin_links) {
      setLinks(() =>
        data.cashin_links
          ? data.cashin_links.map((link) => ({
              value: link,
              error: undefined,
            }))
          : []
      );
    }
  }, [data.cashin_links]);

  useEffect(() => {
    if (error?.data?.errors?.cashin_links) {
      setLinks((prevLinks) =>
        prevLinks.map((link, index) => ({
          ...link,
          error:
            //Trouble on backend "must be a string" returns on invalid link
            error?.data?.errors?.cashin_links[index] == "must be a string"
              ? t("invalidLink")
              : error?.data?.errors?.cashin_links[index],
        }))
      );
    }
  }, [error?.data?.errors?.cashin_links]);

  function getInputLeft(link: Link) {
    if (isLoading || data.state === "verification") {
      return <CircularProgress size={18} color="warning" />;
    }

    if (
      data.verification_result.triggers?.find(
        (result) => result.value === link.value
      )
    ) {
      return <AlertIcon className={styles.iconLeft} />;
    }

    if (
      data.verification_result?.triggers.length > 0 &&
      data.cashin_links?.includes(link.value)
    ) {
      return <CheckIcon className={styles.iconLeft} />;
    }
    return;
  }

  function getInputType(link: Link) {
    if (data.state === "verification") {
      return;
    }
    if (link.error) {
      return "error";
    }
    if (
      data.verification_result.triggers?.some(
        (result) => result.value === link.value
      )
    ) {
      return "error";
    }
    if (
      data.verification_result?.triggers.length > 0 &&
      data.cashin_links?.includes(link.value)
    ) {
      return "success";
    }
    return;
  }

  function getInputError(link: Link) {
    if (data.state === "verification") {
      return;
    }
    if (link.error) {
      return (
        <Text className={styles.error} type="danger">
          {link.error}
        </Text>
      );
    }
    const verificationResult = data.verification_result.triggers?.find(
      (result) => result.value === link.value
    );

    const errorTexts = {
      already_used: t("verification_result.already_used"),
      not_unique: t("verification_result.not_unique"),
      invalid_receiver: t("verification_result.invalid_receiver"),
      invalid_amount: t("verification_result.invalid_amount"),
      invalid_processing_at: t("verification_result.invalid_processing_at"),
    };
    if (verificationResult) {
      return (
        <Text className={styles.error} type="danger">
          {errorTexts[verificationResult.name as "already_used"] ||
            verificationResult.message}
        </Text>
      );
    }
    return;
  }

  return (
    <>
      {links.map((link, index) => (
        <div className={styles.linkWrapper} key={index}>
          <Input
            containerClassName={styles.inputContainer}
            leftElement={getInputLeft(link)}
            type={getInputType(link)}
            rightElement={
              data.state === "verification" ? undefined : (
                <button
                  className={styles.removeLink}
                  type="button"
                  onClick={() => handleRemoveLink(index)}
                >
                  <XIcon />
                </button>
              )
            }
            inputAttributes={{
              disabled: data.state === "verification",
              type: "text",
              value: link.value,
              onChange: (e: any) => handleInputChange(index, e.target.value),
              placeholder: t("placeholder"),
              maxLength: 1024,
            }}
          />
          {getInputError(link)}
        </div>
      ))}
      <Block>
        {error && !error?.data?.errors?.cashin_links && (
          <ErrorParser error={error} />
        )}
        {data.payment_functionality_visible && (
          <Button
            className={styles.button}
            withAnimation
            onClick={handleAddLink}
          >
            <PlusIcon /> {t("add")}
          </Button>
        )}
      </Block>
    </>
  );
};

export default Links;
