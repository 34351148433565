import React from "react";

import styles from "../styles.module.scss";

import classNames from "classnames";
import { ReactComponent as BankIcon } from "../../../../assets/icons/bank.svg";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BankChose({
  bankError,
  selectedBankName,
  selectedBank,
  isProcessing,
}: {
  bankError: string | undefined;
  selectedBankName: string;
  selectedBank: any;
  isProcessing: boolean;
}) {
  const { t } = useTranslation(undefined, { keyPrefix: "orderPage.bankChose" });
  const [searchParams, setSearchParams] = useSearchParams();
  function openSelectBank() {
    searchParams.set("bank_modal", "1");
    setSearchParams(searchParams);
  }

  return (
    <section className={styles.bankChose}>
      {bankError ? (
        <h2 className={classNames(styles.blockTitle, styles.error)}>
          {bankError}
        </h2>
      ) : isProcessing ? (
        <></>
      ) : (
        <h2 className={styles.blockTitle}>{t("title")}</h2>
      )}
      <button
        onClick={openSelectBank}
        className={classNames(styles.paper, styles.selectContainer, {
          [styles.error]: Boolean(bankError),
        })}
      >
        <div className={styles.bank}>
          <div className={styles.bankLogo}>
            {selectedBankName ? (
              <img src={selectedBank?.logo} className="" alt="logo" />
            ) : (
              <BankIcon />
            )}
          </div>
          <div className={styles.bankInfo}>
            {selectedBankName ? (
              <div className={styles.bankName}>
                {selectedBank?.name || selectedBankName}
              </div>
            ) : (
              <div className={styles.bankName}>{t("empty")}</div>
            )}
          </div>
        </div>
        <div className={styles.chose}>
          {t("chose")}{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M4.5 6.75L9 11.25L13.5 6.75"
              stroke="#0052FF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
    </section>
  );
}
