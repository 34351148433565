const profilePageText = {
  profilePage: {
    amount: {
      title: "Доступный объем выплат",
    },
    title: "Управление аккаунтом",
    exit: "Выход",
    reserves: {
      title: "Резервы",
      rules: {
        required: "Поле не заполнено",
        min: `Введите сумму более {{count}}`,
      },
      editText: {
        error: "Ошибка",
        saved: "Резерв сохранен",
        placeholder: "Введите новое значение",
      },
      bank: "Банк",
      add: {
        onSubmit: {
          success: {
            title: "Успех",
            description: "Резерв успешно добавлен",
          },
          error: {
            title: "Ошибка",
            description: "Произошла ошибка при создании резерва",
          },
        },
        bank: {
          placeholder: "Выберите банк",
          required: "Банк не выбран",
        },
        reserve: {
          title: "Резерв",
          required: "Резерв не заполнен",
          min: `Введите сумму более {{count}}`,
        },
        submit: "Добавить резерв",
        addNew: "Добавить новый резерв",
      },
    },
    token: {
      title: "Настройки токена",
      disable: "Отключить",
      enable: "Включить",
      enabled: {
        switch: "Токен включен",
        state: "Вы получаете заявки на выплаты",
        description:
          "При отключении токена активные заявки перейдут к другому исполнителю",
      },
      disabled: {
        switch: "Токен отключен",
        state: "Вы не получаете заявки на выплаты",
        description: "Включите токен, чтобы получать новые заявки на выплаты",
      },
    },
    sbp: {
      disable: "Отключить",
      enable: "Включить",
      enabled: {
        switch: "Выплаты по СБП",
        description: "Включите, чтобы принимать заявки на выплаты по СБП",
      },
      disabled: {
        switch: "Выплаты по СБП",
        description: "Отключите, чтобы не принимать заявки на выплаты по СБП",
      },
    },
    c2c: {
      disable: "Отключить",
      enable: "Включить",
      enabled: {
        switch: "Переводы с карты на карту",
        description:
          "Включите, чтобы принимать заявки на выплаты по номеру карты",
      },
      disabled: {
        switch: "Переводы с карты на карту",
        description:
          "Отключите, чтобы не принимать заявки на выплаты по номеру карты",
      },
    },
    account: {
      disable: "Отключить",
      enable: "Включить",
      switch: "Переводы по номеру счета",
      description: "Заявки на выплаты по номеру счета клиентам Сбербанка",
    },
    cashIn: {
      disable: "Отключить",
      enable: "Включить",
      switch: "Т-Банк Cash-In",
      description: "Возможность делать выплаты через банкоматы Т-Банка",
    },
  },
};

export default profilePageText;
